import React, { useEffect, useState, useRef } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { HiPlusCircle } from 'react-icons/hi';
import StoreLocationIcon from 'components/icons/storeLocation';
import { isVeteranAccount } from 'helpers/utils/isVeteranAccount';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount, useCart } from 'frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import MilitryStar from './militaryStar';
import MyAccount from './myAccount';
import RightColumnItem from './rightColumnItem';
import SearchForm from './searchForm';
import StoreDetails from './storeDetails';

const LoginModal = dynamic(() => import('../Modals/Login/login'));
const FindStore = dynamic(() => import('../Modals/findStore'));
const Hamburger = dynamic(() => import('./hamburger'));
const MiniCart = dynamic(() => import('./miniCart'));

function Header({
  militaryStarApply,
  cartList,
  militaryStar,
  menuData,
  leftColumn,
  rightColumn,
  findStore,
  exchangeLogo,
  militaryStarParentURL,
  weeklyAd,
}) {
  useEffect(() => {
    if (document) {
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      setIsMobile(viewportWidth < tablet);
    }
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const router = useRouter();
  const [toggle, setToggle] = useState(false);
  const storeIcon = findStore?.icon?.media?.file;
  const storeLabel = findStore?.header;
  const logo = exchangeLogo?.icon?.media?.file;
  const logoLink = exchangeLogo?.links;
  const logoMobile = exchangeLogo?.mIcon?.media?.file;
  const [loginModal, setLoginModal] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useFormat({ name: 'common' });
  const [findStoreModal, setFindStoreModal] = useState(false);
  const { login, loggedIn, listingStores, getChannelsSearch, defaultStoreAddress, account, defaultStoreByGeo } =
    useAccount();
  const [defaultStore, setDefaultStore] = useState(false);
  const [channelId, setChannelId] = useState(null);
  const [storeList, setStoresList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isplp, setIsplp] = useState(false);
  const [isScrollOnPLPCLP, setIsScrollOnPLPCLP] = useState(false);

  const showStoreModal = () => {
    setFindStoreModal(true);
    setSearchInput('');
    const fetchChannelList = async () => {
      const channelList: any = await listingStores('');
      setStoresList(channelList);
      if (channelList?.errorCode) {
        setFindStoreModal(false);
      }
    };
    loggedIn && fetchChannelList();
    setStoresList([]);
  };
  const [redirectUrl, setRedirectUrl] = useState(' ');
  const headerRef = useRef<HTMLDivElement>();
  const height = headerRef?.current?.clientHeight;

  function showLogin() {
    setLoginModal(true);
  }

  function closeLoginModal() {
    setLoginModal(false);
  }

  function closeStoreModal() {
    setFindStoreModal(false);
  }

  function updateChannelId(id) {
    setChannelId(id);
  }
  function navigateToCart() {
    showLogin();
    setRedirectUrl('/cart');
  }

  const [geoStore, setGeoStore] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const { studioConfig } = useCart();
  const baseChannelID = studioConfig?.baseChannelId;

  useEffect(() => {
    if (loggedIn && account?.resetDefaultStore && !account?.defaultStore?.address) {
      const fetchData = async () => {
        const response = await defaultStoreByGeo(userLocation);
        setGeoStore(response);
      };
      if (userLocation) {
        fetchData();
      }
    }
  }, [userLocation, account?.resetDefaultStore]);

  useEffect(() => {
    if (loggedIn && !account?.defaultStore?.address) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
          },
          (error) => {
            defaultStoreAddress(baseChannelID);
          },
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }
  }, [loggedIn]);

  const handleRightColumnClick = (Item) => {
    if (Item.header === 'Marketplace') {
      showLogin();
      setRedirectUrl(Item.links);
    } else {
      router.push(Item.links);
    }
  };

  useEffect(() => {
    if (router?.asPath) {
      const isplpValue = router?.asPath?.includes('/browse');
      setIsplp(isplpValue);
    }
  }, [router?.asPath]);

  const { studioConfig: projectConfiguration } = useCart();
  const [templateKeysArray, setTemplateKeysArray] = useState([]);

  useEffect(() => {
    const templateKeysCleanedResponse = projectConfiguration
      ? projectConfiguration.templateKeys.replace(/\\/g, '')
      : '';
    try {
      if (templateKeysCleanedResponse) {
        setTemplateKeysArray(JSON.parse(templateKeysCleanedResponse));
      }
    } catch (error) {
      console.error('assets-cms: Error parsing JSON: %s \n %O', templateKeysCleanedResponse, error);
    }
  }, []);
  const checkLinksMatch = (link) => {
    const extendedLink = link + `.html`;
    return templateKeysArray.includes(extendedLink);
  };

  useEffect(() => {
    if (isplp && isScrollOnPLPCLP) {
      (window as Window).scrollTo(0, 0);
    }
  }, [isplp && isScrollOnPLPCLP]);
  return (
    <>
      <section className="w-full">
        <section className="-mx-4 lg:-mx-0">
          <section
            className={`header border-b pb-3 shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] lg:border-blue-900 lg:shadow-none ${
              isplp ? 'mb-[65px] md:mb-[20px]' : ''
            }`}
          >
            <section className="wrapper">
              <div>
                <section
                  ref={headerRef}
                  className={`headerTop z-10 flex items-stretch justify-between px-4 pt-4 md:px-[30px] ${
                    isplp && !isScrollOnPLPCLP ? 'fixed flex w-full max-w-[1440px] flex-1 bg-white pb-1' : ''
                  }`}
                >
                  <section className="hidden items-center md:flex">
                    <section className="cursor-pointer">
                      <Link href={logoLink}>
                        <a aria-label="My Exchange Logo">
                          <img src={logo} alt="My Exchange Logo" className="mr-[75px]" />
                        </a>
                      </Link>
                    </section>
                  </section>
                  {!(isMobile && isSearchActive) && (
                    <>
                      <section className="order-2 flex items-center md:hidden">
                        <section>
                          <Link href={logoLink}>
                            <a>
                              <img src={logoMobile} alt="My Exchange Logo" className="mx-2" />
                            </a>
                          </Link>
                        </section>
                      </section>

                      <Hamburger
                        menuData={menuData}
                        leftColumn={leftColumn}
                        rightColumn={rightColumn}
                        findStore={findStore}
                        militaryStarData={militaryStar}
                        militaryStarApply={militaryStarApply}
                        militaryStarParentURL={militaryStarParentURL}
                        weeklyAd={weeklyAd}
                        setStoresList={setStoresList}
                        storeList={storeList}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        setIsScrollOnPLPCLP={setIsScrollOnPLPCLP}
                      />
                    </>
                  )}

                  <SearchForm setIsSearchActive={setIsSearchActive} isMobile={isMobile} />
                  {!(isMobile && isSearchActive) && (
                    <>
                      <MyAccount projectConfiguration={studioConfig} />
                      <MilitryStar
                        militaryStarData={militaryStar}
                        militaryStarApply={militaryStarApply}
                        militaryStarParentURL={militaryStarParentURL}
                      />
                      <section className="order-5 flex md:hidden">
                        <p
                          onClick={() => showStoreModal()}
                          className="flex cursor-pointer items-center text-sm font-semibold text-blue-900"
                        >
                          <StoreLocationIcon className="mr-3" />
                        </p>
                      </section>
                      <MiniCart cartList={cartList} showLogin={navigateToCart} />
                    </>
                  )}
                </section>
              </div>
              <div>
                <section
                  className={`headerBottom hidden justify-between px-4 md:flex md:px-[30px]`}
                  style={{ paddingTop: height && isplp && !isScrollOnPLPCLP ? height + 10 : 10 }}
                >
                  <section className="flex">
                    {leftColumn.map((Item, id) => (
                      <section key={id} className="mr-5">
                        {Item?.loginRequired ? (
                          loggedIn ? (
                            <Link href={Item.links}>
                              <a className="flex cursor-pointer items-center text-sm font-semibold text-blue-900 hover:underline">
                                <img src={Item?.icon?.media?.file} alt={`${Item.header} Icon`} className="mr-3" />{' '}
                                {Item.header}
                              </a>
                            </Link>
                          ) : (
                            <section
                              onClick={() => {
                                showLogin();
                                setRedirectUrl(Item?.links);
                              }}
                            >
                              <p className="flex cursor-pointer items-center text-sm font-semibold text-blue-900 hover:underline">
                                <img src={Item?.icon?.media?.file} alt={`${Item.header} Icon`} className="mr-3" />{' '}
                                {Item.header}
                              </p>
                            </section>
                          )
                        ) : (
                          <Link href={Item.links}>
                            <a className="flex cursor-pointer items-center text-sm font-semibold text-blue-900 hover:underline">
                              <img src={Item?.icon?.media?.file} alt={`${Item.header} Icon`} className="mr-3" />{' '}
                              {Item.header}
                            </a>
                          </Link>
                        )}
                      </section>
                    ))}
                    <section>
                      {weeklyAd?.loginRequired ? (
                        loggedIn ? (
                          <section className="mr-5 mb-3 flex items-center hover:underline lg:mb-0">
                            <Link href={weeklyAd?.links}>
                              <a className="flex cursor-pointer items-center text-sm font-semibold text-blue-900">
                                <img
                                  src={weeklyAd?.icon?.media?.file}
                                  alt={`${weeklyAd.header} Icon`}
                                  className="mr-3"
                                />
                                {weeklyAd.header}
                              </a>
                            </Link>
                          </section>
                        ) : (
                          <section
                            onClick={() => {
                              showLogin();
                              setRedirectUrl(weeklyAd?.links);
                            }}
                            className="mr-5 mb-3 flex items-center hover:underline lg:mb-0"
                          >
                            <p className="flex cursor-pointer items-center text-sm font-semibold text-blue-900">
                              <img src={weeklyAd?.icon?.media?.file} alt={`${weeklyAd.header} Icon`} className="mr-3" />{' '}
                              {weeklyAd.header}
                            </p>
                          </section>
                        )
                      ) : (
                        <Link href={weeklyAd?.links}>
                          <a className=" flex cursor-pointer items-center text-sm font-semibold text-blue-900 hover:underline">
                            <img src={weeklyAd?.icon?.media?.file} alt="Weekly Ad" className="mr-3" />{' '}
                            {weeklyAd?.header}
                          </a>
                        </Link>
                      )}
                    </section>
                  </section>
                  <section className="z-8 relative flex justify-between md:relative lg:static">
                    <section
                      onClick={() => setToggle(!toggle)}
                      className="flex items-center text-sm font-semibold text-blue-900 md:mr-5 lg:hidden"
                    >
                      <HiPlusCircle className="mr-3 text-xl" />
                      {formatMessage({ id: 'exploreMore', defaultMessage: 'Explore More' })}
                    </section>
                    <section
                      className={
                        toggle
                          ? 'absolute top-10 -left-1 z-30 block w-64 border border-gray-600 bg-white p-5 lg:static lg:flex lg:w-auto lg:border-0 lg:p-0'
                          : 'absolute top-10 -left-1 hidden w-64 border border-gray-600 bg-white p-5 lg:static lg:flex lg:w-auto lg:border-0 lg:p-0'
                      }
                    >
                      <section className="boxArrow absolute left-20 -top-4 h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600 md:block lg:hidden">
                        <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
                      </section>
                      {rightColumn.map((Item, rightId) => (
                        <RightColumnItem
                          key={rightId}
                          Item={Item}
                          loggedIn={loggedIn}
                          showLogin={showLogin}
                          setRedirectUrl={setRedirectUrl}
                          weeklyAd={weeklyAd}
                          rightId={rightId}
                        />
                      ))}
                    </section>
                    {!isVeteranAccount(account) && (
                      <StoreDetails
                        account={account}
                        showStoreModal={showStoreModal}
                        formatMessage={formatMessage}
                        setDefaultStore={setDefaultStore}
                        storeIcon={storeIcon}
                        loggedIn={loggedIn}
                        defaultStore={defaultStore}
                        storeLabel={storeLabel}
                        setFindStoreModal={setFindStoreModal}
                      />
                    )}
                  </section>
                </section>
              </div>
            </section>
          </section>
        </section>
        <LoginModal
          loginModal={loginModal}
          closeModal={closeLoginModal}
          closeAccountFlyout={closeLoginModal}
          loginCallBack={() => (channelId ? defaultStoreAddress(channelId) : router.push(redirectUrl))}
        />
        <FindStore
          findStoreModal={findStoreModal}
          closeModal={closeStoreModal}
          setLoginModal={setLoginModal}
          setFindStoreModal={setFindStoreModal}
          channelId={channelId}
          updateChannelId={updateChannelId}
          setStoresList={setStoresList}
          storeList={storeList}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      </section>
    </>
  );
}
export default Header;
