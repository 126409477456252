import React, { useEffect, useState } from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { useAccount } from 'frontastic';
function ConfirmDelete({ description, buttonText, successMessage, errorMessage }) {
  const { confirmDeleteAccount } = useAccount();
  const [showDesc, setShowDesc] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [apiError, setApiError] = useState(false);
  let CID, TOKEN;
  useEffect(() => {
    const searchParams = new URLSearchParams(window?.location?.search);
    CID = searchParams.get('cid');
    TOKEN = searchParams.get('token');
    if (CID && TOKEN) {
      setShowDesc(true);
    }
  }, []);
  const handleClick = async () => {
    const searchParams = new URLSearchParams(window?.location?.search);
    const cid = parseInt(searchParams.get('cid'));
    const token = searchParams.get('token');
    const payload = {
      cid,
      token,
    };
    try {
      const res = await confirmDeleteAccount(payload);
      if (res.errorCode) {
        setApiError(true);
        setShowDesc(true);
        setSuccessMsg(false);
      } else {
        setSuccessMsg(true);
        setShowDesc(false);
        setApiError(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <section className="m-auto w-96 py-10">
      {showDesc && (
        <>
          <h1 className="mb-5 text-affes-xl">{description}</h1>
          <button
            type="button"
            onClick={handleClick}
            className="mb-2 cursor-pointer rounded bg-blue-900 px-4 py-2 text-center text-affes-sm font-bold uppercase text-white"
          >
            {buttonText}
          </button>
        </>
      )}
      {apiError && (
        <section className="flex items-center">
          <MdOutlineErrorOutline className="mr-3 text-[30px] text-red-700" />
          <p className="text-[20px] text-red-700">{errorMessage}</p>
        </section>
      )}
      {successMsg && (
        <section className="flex items-center">
          <FaRegCheckCircle className="mr-3 text-[30px] text-green-700" />{' '}
          <p className="text-[20px] font-bold text-green-700">{successMessage}</p>{' '}
        </section>
      )}
    </section>
  );
}
export default ConfirmDelete;
