import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import InputMask from 'react-input-mask';
import Loader from 'components/commercetools-ui/loader/Loader';
import CreditCardIcon from 'components/icons/creditCardIcon';
import MegaMenuArrow from 'components/images/megamenu-arrow.png';
import { useFormat } from 'helpers/hooks/useFormat';
import { getCartItems, setBillingAddress } from 'frontastic/actions/cart';
import { useAccount, useCart } from 'frontastic/provider';
import IdentifyCardType from '../../../../helpers/utils/IdentifyCardType';
import ValidateCardNumber from '../../../../helpers/utils/validateCardNumber';
import HelpIcon from '../../../icons/helpIcon';
import AddAddressModal from './addAddressModal';

interface EditBillingProp {
  closeModal: () => void;
  showCardField: boolean;
  data: any;
  setSelectedCardData;
  orderId?: string;
  isSubscriptionCard?: boolean;
  handleGetSubscriptionTemplate?: any;
  isCheckout?: boolean;
  newCreditCardAddedToAccount?: any;
}

function NewCreditCard({
  closeModal,
  showCardField,
  data: selectedCard,
  setSelectedCardData,
  orderId,
  isSubscriptionCard,
  handleGetSubscriptionTemplate,
  isCheckout = false,
  newCreditCardAddedToAccount = () => {},
}: EditBillingProp) {
  const { addCreditCardForPayment } = useCart();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const [addCreditCard, setAddCreditCard] = useState(true);
  const creditCardDetails = useRef({ pan: '', cvv: '', holderName: '', expiryDate: '' });
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);

  const [choosenAddress, setChoosenAddress] = useState(null);
  const [dropDownSelectedAddress, setDropDownSelectedAddress] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const { getCreditCards, addresses = [] } = useAccount();
  const { addCreditCards, updateCreditCards, updateSubscriptionPayment } = useAccount();
  const [detectedCardType, setDetectedCardType] = useState('');
  const [apiErrorMessage, setAPIErrorMessage] = useState(null);
  const [notValidExpDate, setNotValidExpDate] = useState(false);
  const [notValidExpMon, setNotValidExpMon] = useState(false);
  const [newCardToken, setNewCardToken] = useState({});
  const [storeToWallet, setStoreToWallet] = useState(true);
  const [invalidCardError, setInvalidCardError] = useState(null);
  useEffect(() => {
    const defaultBillingAddress = addresses?.find((address) => address?.isDefaultBillingAddress);
    const selectedAddress = addresses?.find((address) => address?.addressId === dropDownSelectedAddress);
    if (selectedAddress) {
      setChoosenAddress(selectedAddress);
    } else if (defaultBillingAddress) {
      setDropDownSelectedAddress(defaultBillingAddress.addressId);
      setChoosenAddress(defaultBillingAddress);
    } else if (addresses?.length) {
      setChoosenAddress(addresses?.[0]);
      setDropDownSelectedAddress(addresses?.[0].addressId);
    }
  }, [addresses, dropDownSelectedAddress]);

  const afterSavingAddress = async (data) => {
    setShowAddAddressModal(false);
    setAddCreditCard(true);
  };

  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

  const withoutSavingAddress = (data) => {
    setShowLoader(true);
  };
  const addressDropdownSelection = (e) => {
    const selectedAddressId = e.target.value;
    const selectedAddress = addresses.find((address) => address.addressId === selectedAddressId);
    setDropDownSelectedAddress(selectedAddressId);
    setChoosenAddress(selectedAddress);
  };
  const getFormattedAddress = (address) => {
    const formattedAddress = [
      address.additionalAddressInfo,
      address.streetName,
      address.streetNumber,
      address.city,
      address.state,
      address.postalCode,
    ]
      .filter(Boolean)
      .join(', ');

    return formattedAddress;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const onSubmit = async (data) => {
    setShowLoader(true);
    let ExpMonth, ExpYear;

    if (showCardField) {
      const expDate = data.expirationDate;
      const cleanedValue = expDate.replace(/\D/g, '');
      ExpMonth = cleanedValue.substring(0, 2);
      ExpYear = cleanedValue.substring(2, 4);
    }
    let preferredCardValue = 'N';
    if (getCreditCards?.response?.responsedescription === 'NO_LINKED_CARDS_FOUND') {
      preferredCardValue = 'Y';
    }

    const addNewCardPayload = {
      cardholderName: data?.nameOnCard,
      cardSecurityCode: data?.securityCode,
      state: choosenAddress?.state,
      country: choosenAddress?.country,
      zipcode: choosenAddress?.postalCode,
      billingAddress1: choosenAddress?.streetName,
      billingAddress2: choosenAddress?.streetNumber,
      city: choosenAddress?.city,
      billingPhone: choosenAddress?.phone,
    };

    const addCardPayload = {
      ...addNewCardPayload,
      preferredCard: preferredCardValue,
      pan: data.creditCardNumber,
      expiryDate: ExpYear + ExpMonth,
      cardType: detectedCardType,
    };

    if (showCardField) {
      if (isCheckout && !storeToWallet) {
        try {
          const response = await addCreditCardForPayment({
            paymentMethod: 'creditCard',
            cardType: detectedCardType,
            expDate: ExpYear + ExpMonth,
            billingAddress: {
              firstName: data?.nameOnCard,
              streetName: choosenAddress?.streetName,
              streetNumber: choosenAddress?.streetNumber,
              postalCode: choosenAddress?.postalCode,
              city: choosenAddress?.city,
              country: choosenAddress?.country,
              state: choosenAddress?.state,
              phone: choosenAddress?.phone,
            },
            cardholderName: data?.nameOnCard,
            cardNumber: data.creditCardNumber,
            cardSecurityCode: data?.securityCode,
          });

          if (response?.errorCode) {
            setInvalidCardError(response?.body);
          } else {
            closeModal();
          }
        } catch (error) {
          setInvalidCardError(error.message || 'An error occurred while adding the credit card.');
        } finally {
          setShowLoader(false);
        }
      } else {
        try {
          const updatedCardData = await addCreditCards(addCardPayload);
          if (updatedCardData?.errorCode != 500) {
            setSelectedCardData(updatedCardData);
            newCreditCardAddedToAccount();
            setNewCardToken(
              updatedCardData?.response?.bankcardaccount?.[0]?.token ||
                updatedCardData?.response?.milstaraccount?.[0]?.token,
            );
          }
          if (isSubscriptionCard) {
            await setBillingAddress({
              billingAddress: {
                addressId: choosenAddress.addressId,
              },
            });
          }
          setShowLoader(true);
          if (updatedCardData?.errorCode) {
            setAPIErrorMessage(updatedCardData?.body);
            setShowLoader(false);
          } else {
            isSubscriptionCard ? null : closeModal();
          }
        } catch (error) {
          console.error('Error updating credit cards:', error);
          setShowLoader(false);
        }
      }
    } else if (!showCardField) {
      const updateCardPayload = {
        ...addNewCardPayload,
        token: selectedCard.token,
        expiryDate: selectedCard?.expirydate,
        cardType: selectedCard?.cardtype,
      };

      try {
        const updatedCardData = await updateCreditCards(updateCardPayload);
        setSelectedCardData(updatedCardData);
        setShowLoader(true);
        if (updatedCardData?.errorCode) {
          setAPIErrorMessage(updatedCardData?.body);
          setShowLoader(false);
        } else {
          const cart = await getCartItems();
          const creditCardPayment = cart?.payments?.find((payment) => payment.paymentMethod === 'creditCard');
          if (creditCardPayment && creditCardPayment.token === selectedCard.token) {
            await setBillingAddress({
              billingAddress: {
                addressId: choosenAddress.addressId,
              },
            });
          }
          closeModal();
        }
      } catch (error) {
        console.error('Error updating credit cards:', error);
      }
    } else {
      await addCreditCards(addCardPayload);
    }
  };
  const [showM, setShowM] = useState(false);

  const newCard =
    getCreditCards?.response?.bankcardaccount?.find((card) => card?.token === newCardToken) ||
    getCreditCards?.response?.milstaraccount?.find((card) => card?.token === newCardToken);
  const updatePaymentSubscription = async (newCard) => {
    const res = await updateSubscriptionPayment(
      orderId,
      newCard?.token,
      'creditCard',
      newCard?.cardtype,
      choosenAddress,
      newCard?.expirydate,
    );
    if (res?.body) {
      setAPIErrorMessage(res?.body);
      setShowLoader(false);
    } else {
      await handleGetSubscriptionTemplate();
      closeModal();
    }
  };

  useEffect(() => {
    if (newCard) {
      updatePaymentSubscription(newCard);
    }
  }, [newCard]);

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section
                  className="absolute inset-0"
                  onClick={closeModal}
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="creditCardModalLabel"
                >
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[700px] w-[90%] -translate-x-1/2 -translate-y-1/2  overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>

                    <section className="">
                      {addCreditCard && (
                        <section className="">
                          <form onSubmit={handleSubmit(onSubmit)} aria-labelledby="creditCardModalLabel">
                            <section className="z-30">
                              <section className="modalHeader relative mb-[38px]" role="heading">
                                <button type="button" className="absolute right-0 top-0" onClick={closeModal}>
                                  <GrClose />
                                </button>

                                {showCardField ? (
                                  <h3
                                    className="text-left text-[18px] font-bold leading-[21.6px] text-gray-900"
                                    id="creditCardModalLabel"
                                  >
                                    {formatCheckoutMessage({
                                      id: 'add.credit.card',
                                      defaultMessage: 'Add Credit Card',
                                    })}
                                  </h3>
                                ) : (
                                  <h3
                                    className="text-left text-[18px] font-bold leading-[21.6px] text-gray-900"
                                    id="creditCardModalLabel"
                                  >
                                    {formatCheckoutMessage({
                                      id: 'edit.credit.card',
                                      defaultMessage: 'Edit Credit Card',
                                    })}
                                  </h3>
                                )}
                                {apiErrorMessage && (
                                  <p className="text-left text-red-900" role="alert">
                                    {apiErrorMessage}
                                  </p>
                                )}
                                {invalidCardError && (
                                  <p className="text-left text-red-900" role="alert">
                                    {invalidCardError}
                                  </p>
                                )}
                              </section>
                              <section>
                                <section>
                                  <section className="mb-[16px] justify-between md:mb-6 md:flex">
                                    <section>
                                      <label
                                        htmlFor="nameOnCard"
                                        className="mb-[9px] block text-left text-[12px] font-bold leading-[14.4px] text-gray-800"
                                      >
                                        {formatCheckoutMessage({
                                          id: 'name.on.card',
                                          defaultMessage: 'Name on Card',
                                        })}
                                        <sup className="ml-1 text-[12px] font-bold leading-[14.4px] text-[#DA0F0F]">
                                          *
                                        </sup>
                                      </label>
                                      <input
                                        type="text"
                                        name="nameOnCard"
                                        id="nameOnCard"
                                        className={`min-h-[36px] w-full rounded-sm border border-gray-600 py-[5px] px-3 md:w-[250px] ${
                                          errors.nameOnCard
                                            ? 'border-red-500 text-red-500'
                                            : 'border-gray-600 text-black-500'
                                        }`}
                                        {...register('nameOnCard', {
                                          required: formatCheckoutMessage({
                                            id: 'add.new.creditcard.fullname.required',
                                            defaultMessage: 'Please enter cardholder’s full name.',
                                          }),
                                          validate: (value) => {
                                            if (!Boolean(/^([^0-9]*)$/.test(value))) {
                                              return 'Numeric values are not allowed.';
                                            }
                                            return true;
                                          },
                                        })}
                                        defaultValue={`${!showCardField ? selectedCard?.cardholdername : ''}`}
                                        onChange={(e) => {
                                          const alphanumericValue = e.target.value.replace(/[^A-Za-z\d\s]/g, '');
                                          e.target.value = alphanumericValue;
                                          creditCardDetails.current.holderName = alphanumericValue;
                                          clearErrors('nameOnCard');
                                        }}
                                        maxLength={40}
                                        aria-invalid={errors.nameOnCard ? 'true' : 'false'}
                                        aria-describedby="nameOnCardError"
                                      />
                                      {errors.nameOnCard && (
                                        <p
                                          id="nameOnCardError"
                                          className="text-left text-[12px] text-red-500"
                                          role="alert"
                                        >
                                          {errors.nameOnCard.message}
                                        </p>
                                      )}
                                    </section>
                                  </section>
                                  {!showCardField && (
                                    <section
                                      className="mb-[16px] md:mr-[14px]  md:mb-4 md:w-[8rem]"
                                      role="group"
                                      aria-labelledby="securityCodeLabel"
                                    >
                                      <section className="md:w-[6.7rem]">
                                        <div className="flex justify-between md:flex">
                                          <label
                                            id="securityCodeLabel"
                                            htmlFor="securityCode"
                                            className="mb-[9px] flex justify-between text-left text-[12px] font-bold leading-[14.4px] text-gray-800"
                                          >
                                            {formatCommonMessage({
                                              id: 'securityCode',
                                              defaultMessage: 'Security Code',
                                            })}
                                            <span className="text-affes-sm font-bold leading-[14.4px] text-red-500">
                                              *
                                            </span>
                                          </label>
                                          <div className="group relative">
                                            <button
                                              className="text-primary ml-[10px] cursor-pointer text-lg text-blue-900"
                                              onMouseEnter={(event) => {
                                                const popupScroll =
                                                  event?.currentTarget?.nextElementSibling?.querySelector(
                                                    '.popup-scroll',
                                                  );
                                                if (popupScroll && popupScroll?.scrollTop !== 0) {
                                                  popupScroll.scrollTop = 0;
                                                }
                                              }}
                                              type="button"
                                            >
                                              <HelpIcon />
                                            </button>
                                            <div className="text-black -top-30 invisible absolute right-12 bottom-5 z-10 w-[12rem] transition-all duration-300 group-hover:visible group-hover:opacity-100">
                                              <div className="w-[15rem] translate-x-3 rounded  border border-solid bg-white text-xs md:w-[20rem] md:translate-x-1/2 md:text-sm">
                                                <div className="popup-scroll overflow-y-auto p-2">
                                                  <p className="text-gray-800">
                                                    {formatCommonMessage({
                                                      id: 'SecurityCodeMessage',
                                                      defaultMessage:
                                                        'Your CVV (Card Verification Value) is the 3- or 4-digit code printed on your credit or debit card. This number verifies that you are using your own card instead of stolen information. The Exchange uses this number to make your purchase more secure and will not store it once your transaction is complete.',
                                                    })}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="boxArrow mb-[2px] translate-x-[52px] rotate-180">
                                                <img
                                                  src={MegaMenuArrow.src}
                                                  alt="Mega Menu Arrow Icon"
                                                  className="w-[24px] "
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="flex w-full justify-between">
                                          <input
                                            type="text"
                                            id="securityCode"
                                            name="securityCode"
                                            maxLength={4}
                                            className={`min-h-[36px] w-full rounded-sm border-gray-600 py-[5px] px-3 text-[14px] placeholder:text-gray-600 ${
                                              errors.securityCode
                                                ? 'border-red-500 text-red-500'
                                                : 'border-gray-600 text-black-500'
                                            }`}
                                            {...register('securityCode', {
                                              required: formatCheckoutMessage({
                                                id: 'add.new.creditcard.cvv.required',
                                                defaultMessage: 'Security Code is required.',
                                              }),
                                              pattern: /^[0-9]+$/i,
                                              minLength: {
                                                value: selectedCard?.cardtype === 'Amex' ? 4 : 3,
                                                message: `Security code must have ${
                                                  selectedCard?.cardtype === 'Amex' ? '4' : '3'
                                                } digits`,
                                              },
                                              maxLength: {
                                                value: selectedCard?.cardtype === 'Amex' ? 4 : 3,
                                                message: `Security code cannot exceed ${
                                                  selectedCard?.cardtype === 'Amex' ? '4' : '3'
                                                } digits`,
                                              },

                                              validate: {
                                                noTripleZero: (value) => {
                                                  if (/^0{3,4}$/.test(value)) {
                                                    return 'Please enter valid security code';
                                                  }
                                                  return true;
                                                },
                                              },
                                            })}
                                            onChange={(e) => {
                                              e.target.value = e.target.value.replace(/\D/g, '');
                                              creditCardDetails.current.cvv = e.target.value;
                                              clearErrors('securityCode');
                                            }}
                                            aria-invalid={errors.securityCode ? 'true' : 'false'}
                                            aria-describedby="securityCodeError"
                                          />
                                        </div>
                                        {errors.securityCode && (
                                          <p
                                            id="securityCodeError"
                                            className="text-left text-[12px] text-red-500"
                                            role="alert"
                                          >
                                            {errors.securityCode.message}
                                          </p>
                                        )}
                                      </section>
                                    </section>
                                  )}
                                </section>
                                {showCardField && (
                                  <>
                                    <section className="md:mb-6" role="group" aria-labelledby="creditCardNumberLabel">
                                      <label
                                        htmlFor="creditCardNumber"
                                        className="mb-[9px] block text-left text-[12px] font-bold leading-[14.4px] text-gray-800"
                                        id="creditCardNumberLabel"
                                      >
                                        {formatCheckoutMessage({
                                          id: 'credit.card.number',
                                          defaultMessage: 'Credit Card Number',
                                        })}{' '}
                                        <sup className="ml-1 text-[12px] font-bold leading-[14.4px] text-[#DA0F0F]">
                                          *
                                        </sup>
                                      </label>
                                      <section className="relative w-full md:w-[250px]">
                                        <CreditCardIcon className="absolute top-[7px] left-2" />
                                        <input
                                          type="text"
                                          name="creditCardNumber"
                                          id="creditCardNumber"
                                          maxLength={16}
                                          className={`min-h-[36px] w-full rounded border py-[5px] pl-9 pr-3 ${
                                            errors.creditCardNumber
                                              ? 'border-2 border-red-500 text-red-500'
                                              : 'border-gray-600 text-black-500'
                                          }`}
                                          {...register('creditCardNumber', {
                                            required: 'Please provide credit card number.',
                                            validate: (value) =>
                                              ValidateCardNumber(value) ||
                                              formatCheckoutMessage({
                                                id: 'add.new.creditcard.invalid',
                                                defaultMessage:
                                                  'Please try again. Enter the number exactly as it appears on your credit card.',
                                              }),
                                          })}
                                          onChange={(e) => {
                                            const numericValue = e.target.value.replace(/\D/g, '');
                                            e.target.value = numericValue;
                                            creditCardDetails.current.pan = numericValue;
                                            setDetectedCardType(IdentifyCardType(numericValue, false));
                                            clearErrors('creditCardNumber');
                                          }}
                                          aria-invalid={errors.creditCardNumber ? 'true' : 'false'}
                                          aria-describedby="creditCardNumberError"
                                        />
                                      </section>
                                      {errors.creditCardNumber && (
                                        <p id="creditCardNumberError" className="text-left text-[12px] text-red-500">
                                          {errors.creditCardNumber.message}
                                        </p>
                                      )}
                                    </section>
                                    <section
                                      className="mt-[10px] mb-[20px] md:mb-6 md:mt-0 md:flex"
                                      role="group"
                                      aria-labelledby="expirationDateLabel"
                                    >
                                      <section className="mb-[16px] md:mr-[14px] md:mb-0 md:w-[8rem]">
                                        <p>
                                          <label
                                            id="expirationDateLabel"
                                            htmlFor="expirationDate"
                                            className="mb-[9px] block text-left text-[12px] font-bold leading-[14.4px] text-gray-800"
                                          >
                                            {formatCheckoutMessage({
                                              id: 'expiration.date',
                                              defaultMessage: 'Expiration Date',
                                            })}{' '}
                                            <sup className="ml-1 text-[12px] font-bold leading-[14.4px] text-[#DA0F0F]">
                                              *
                                            </sup>
                                          </label>
                                          <InputMask
                                            mask="99/99"
                                            maskChar=""
                                            className={`min-h-[36px] w-full rounded-sm border border-gray-600 py-[5px] px-3 text-[14px] placeholder:text-gray-600 ${
                                              errors.expirationDate || notValidExpDate || notValidExpMon
                                                ? 'border-red-500 text-red-500'
                                                : 'text-black border-gray-600'
                                            }`}
                                            {...register('expirationDate', {
                                              required: 'Expiration date is required',
                                            })}
                                            placeholder="mm/yy"
                                            onChange={(e) => {
                                              creditCardDetails.current.expiryDate = e.target.value;
                                              const [expMon, expYear] = e.target.value.split('/');
                                              const [currentMonth, currentYear] = [
                                                new Date().getMonth() + 1,
                                                new Date().getFullYear() % 100,
                                              ];
                                              setNotValidExpDate(
                                                /^\d{2}$/.test(expYear) &&
                                                  (+expYear < currentYear ||
                                                    (+expYear === currentYear && +expMon < currentMonth)),
                                              );
                                              setNotValidExpMon(+expMon > 12);
                                              clearErrors('expirationDate');
                                            }}
                                            aria-invalid={
                                              errors.expirationDate || notValidExpDate || notValidExpMon
                                                ? 'true'
                                                : 'false'
                                            }
                                            aria-describedby="expirationDateError"
                                          />
                                          {errors.expirationDate ? (
                                            <p
                                              id="expirationDateError"
                                              className="text-left text-[12px] text-red-500"
                                              role="alert"
                                            >
                                              {errors.expirationDate.message}
                                            </p>
                                          ) : notValidExpDate ? (
                                            <p
                                              id="expirationDateError"
                                              className="text-left text-[12px] text-red-500"
                                              role="alert"
                                            >
                                              {formatCheckoutMessage({
                                                id: 'notValidExpDate',
                                                defaultMessage: 'Expiration date must not be in the past.',
                                              })}
                                            </p>
                                          ) : notValidExpMon ? (
                                            <p
                                              id="expirationDateError"
                                              className="text-left text-[12px] text-red-500"
                                              role="alert"
                                            >
                                              {formatCheckoutMessage({
                                                id: 'notValidExpMon',
                                                defaultMessage: 'Expiration month must not exceed than 12.',
                                              })}
                                            </p>
                                          ) : null}
                                        </p>
                                      </section>
                                      <section
                                        className="md:w-[6.7rem]"
                                        role="group"
                                        aria-labelledby="securityCodeLabel"
                                      >
                                        <div className="flex justify-between md:flex">
                                          <label
                                            id="securityCodeLabel"
                                            htmlFor="securityCode"
                                            className="mb-[9px] flex justify-between text-left text-[12px] font-bold leading-[14.4px] text-gray-800"
                                          >
                                            {formatCommonMessage({
                                              id: 'securityCode',
                                              defaultMessage: 'Security Code',
                                            })}
                                            <span className="text-affes-sm font-bold leading-[14.4px] text-red-500">
                                              *
                                            </span>
                                          </label>
                                          <div className="group relative">
                                            <button
                                              className="text-primary ml-[10px] cursor-pointer text-lg text-blue-900"
                                              onMouseEnter={(event) => {
                                                setShowM(true);
                                                const popupScroll =
                                                  event?.currentTarget?.nextElementSibling?.querySelector(
                                                    '.popup-scroll',
                                                  );
                                                if (popupScroll && popupScroll?.scrollTop !== 0) {
                                                  popupScroll.scrollTop = 0;
                                                }
                                              }}
                                              onMouseLeave={() => setShowM(false)}
                                              type="button"
                                            >
                                              <HelpIcon />
                                            </button>
                                            {showM && (
                                              <div className="text-black -top-30 invisible absolute right-12 bottom-5 z-10 w-[12rem] transition-all duration-300 group-hover:visible group-hover:opacity-100">
                                                <div className="w-[15rem] translate-x-3 rounded  border border-solid bg-white text-xs md:w-[16rem] md:translate-x-1/2 md:text-sm">
                                                  <div className="popup-scroll overflow-y-auto p-2">
                                                    <p className="text-gray-800">
                                                      {formatCommonMessage({
                                                        id: 'SecurityCodeMessage',
                                                        defaultMessage:
                                                          'Your CVV (Card Verification Value) is the 3- or 4-digit code printed on your credit or debit card. This number verifies that you are using your own card instead of stolen information. The Exchange uses this number to make your purchase more secure and will not store it once your transaction is complete.',
                                                      })}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="boxArrow mb-[2px] translate-x-[52px] rotate-180">
                                                  <img
                                                    src={MegaMenuArrow.src}
                                                    alt="Mega Menu Arrow Icon"
                                                    className="w-[24px] "
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="flex w-full justify-between">
                                          <input
                                            type="text"
                                            id="securityCode"
                                            name="securityCode"
                                            maxLength={4}
                                            className={`min-h-[36px] w-full rounded-sm border-gray-600 py-[5px] px-3 text-[14px] placeholder:text-gray-600 ${
                                              errors.securityCode
                                                ? 'border-red-500 text-red-500'
                                                : 'border-gray-600 text-black-500'
                                            }`}
                                            {...register('securityCode', {
                                              required: formatCheckoutMessage({
                                                id: 'add.new.creditcard.cvv.required',
                                                defaultMessage: 'Security Code is required.',
                                              }),
                                              pattern: /^[0-9]+$/i,
                                              minLength: {
                                                value: detectedCardType === 'Amex' ? 4 : 3,
                                                message: `Security code must have ${
                                                  detectedCardType === 'Amex' ? '4' : '3'
                                                } digits`,
                                              },
                                              maxLength: {
                                                value: detectedCardType === 'Amex' ? 4 : 3,
                                                message: `Security code cannot exceed ${
                                                  detectedCardType === 'Amex' ? '4' : '3'
                                                } digits`,
                                              },

                                              validate: {
                                                noTripleZero: (value) => {
                                                  if (/^0{3,4}$/.test(value)) {
                                                    return 'Please enter valid security code';
                                                  }
                                                  return true;
                                                },
                                              },
                                            })}
                                            onChange={(e) => {
                                              e.target.value = e.target.value.replace(/\D/g, '');
                                              creditCardDetails.current.cvv = e.target.value;
                                              clearErrors('securityCode');
                                            }}
                                            aria-invalid={errors.securityCode ? 'true' : 'false'}
                                            aria-describedby="securityCodeError"
                                          />
                                        </div>
                                        {errors.securityCode && (
                                          <p
                                            id="securityCodeError"
                                            className="text-left text-[12px] text-red-500"
                                            role="alert"
                                          >
                                            {errors.securityCode.message}
                                          </p>
                                        )}
                                      </section>
                                    </section>
                                  </>
                                )}
                                {showCardField && isCheckout && (
                                  <>
                                    <section
                                      className="mt-[10px] mb-[20px] md:mb-6 md:mt-0 md:flex"
                                      aria-label="Store card to wallet"
                                      tabIndex={0}
                                      role="checkbox"
                                    >
                                      <input
                                        type="checkbox"
                                        name="storeToWallet"
                                        className="mr-2 h-6 w-6 rounded-sm"
                                        id="store-to-wallet"
                                        onChange={(e) => setStoreToWallet(e.target.checked)}
                                        checked={storeToWallet}
                                      />{' '}
                                      <label htmlFor="store-to-wallet" className="text-affes-sm text-gray-900">
                                        {formatCheckoutMessage({
                                          id: 'saveCardToAccount',
                                          defaultMessage: 'Save card to account',
                                        })}
                                      </label>
                                    </section>
                                  </>
                                )}
                                <section className="text-left" aria-labelledby="billingAddressHeading">
                                  <h4
                                    id="billingAddressHeading"
                                    className="mb-5 text-[18px] font-bold leading-[21.6px] text-gray-900"
                                  >
                                    {formatCheckoutMessage({
                                      id: 'billingAddress',
                                      defaultMessage: 'Billing address',
                                    })}
                                  </h4>
                                  <section className="mb-[15px]">
                                    <p>
                                      <label
                                        htmlFor="firstName"
                                        className="mb-[9px] block text-[12px] font-bold leading-[14.4px] text-gray-800"
                                      >
                                        {formatCheckoutMessage({
                                          id: 'select.existing.address',
                                          defaultMessage: 'Select Existing Address',
                                        })}{' '}
                                        <sup className="ml-1 text-[12px] font-bold leading-[14.4px] text-[#DA0F0F]">
                                          *
                                        </sup>
                                      </label>

                                      <select
                                        className="select h-[36px] w-full rounded  border-gray-600 py-[6px] px-[10px] leading-[26px] text-gray-900"
                                        onChange={addressDropdownSelection}
                                        value={dropDownSelectedAddress}
                                        required
                                        aria-required="true"
                                        aria-labelledby="addressDropdownLabel"
                                      >
                                        {addresses?.map((address, index) => (
                                          <option
                                            selected={dropDownSelectedAddress == address.addressId}
                                            key={address.addressId}
                                            value={address.addressId}
                                          >
                                            {getFormattedAddress(address)}
                                          </option>
                                        ))}
                                      </select>
                                    </p>
                                  </section>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowAddAddressModal(true);
                                    }}
                                    className="mb-9 cursor-pointer text-affes-base leading-[16.8px] text-blue-900"
                                  >
                                    {formatCheckoutMessage({
                                      id: 'add.new.address',
                                      defaultMessage: 'Add new address ',
                                    })}
                                  </button>
                                </section>
                                <section className="modalFooter text-left" aria-labelledby="modalFooterHeading">
                                  <button
                                    type="submit"
                                    name=""
                                    className="w-full rounded-sm bg-blue-900 py-3 text-affes-xl font-bold leading-[19.2px] text-white md:mr-10 md:w-[136px]"
                                    aria-labelledby="continueButtonLabel"
                                  >
                                    {formatCheckoutMessage({ id: 'continue', defaultMessage: 'Continue' })}
                                  </button>
                                  <button
                                    className="w-full py-3 text-affes-xl font-bold leading-[19.2px] text-blue-900 md:w-auto"
                                    type="button"
                                    onClick={() => {
                                      closeModal();
                                      setAddCreditCard(true);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </section>
                              </section>
                            </section>
                          </form>
                        </section>
                      )}
                    </section>
                  </section>
                </section>
                {showAddAddressModal && (
                  <AddAddressModal
                    withoutSavingAddress={withoutSavingAddress}
                    afterSavingAddress={afterSavingAddress}
                    closeAddAddressModal={() => setShowAddAddressModal(false)}
                    parentLoaderState={showLoader}
                    alwaysSave={false}
                    defaultValues={null}
                    editAddress={false}
                    isCart={false}
                    existingAddresses={addresses}
                    addressType="Billing"
                  ></AddAddressModal>
                )}
                {showLoader && <Loader />}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default NewCreditCard;
