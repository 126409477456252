import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { PencilAltIcon as EditIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import { mutate } from 'swr';
import AddNewCard from 'components/commercetools-ui/aafes/Modals/addNewCard';
import CheckGiftCardModal from 'components/commercetools-ui/aafes/Modals/checkGiftCardModal';
import Loader from 'components/commercetools-ui/loader/Loader';
import WarningIcon from 'components/icons/warning';
import { GA_WALLET } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import CalculateExpDate from 'helpers/utils/CalculateExpDate';
import { checkSessionExpiration } from 'helpers/utils/checkSessionExpiration';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import RenderCardsImages from 'helpers/utils/renderCardsImages';
import { useAccount, useCart } from 'frontastic';
const LoginModal = dynamic(() => import('../../../aafes/Modals/Login/login'));
const Wallet = () => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { getCreditCards, deleteCreditCards, updateCreditCards } = useAccount();
  const [getCardType, setGetCardType] = useState(null);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [EditCardDetails, setEditCardDetails] = useState(true);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [showRemove, setShowRemove] = useState(false);
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);
  const [selectedCardForDelete, setSelectedCardForDelete] = useState(null);
  const [allCards, setAllCards] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [apiErrorMessage, setAPIErrorMessage] = useState(null);
  const { trackMyAccount } = useGATrackUserAction();
  const { account } = useAccount();
  const [showGiftCards, setShowGiftCards] = useState(false);
  const { studioConfig, setCartProductDetailEventSent, data } = useCart();

  const getCards = async () => {
    setShowGiftCards(true);
    const milstarCards = getCreditCards?.response?.milstaraccount ?? [];
    const bankCards = getCreditCards?.response?.bankcardaccount ?? [];
    const mergedCards = [...milstarCards, ...bankCards];
    setAllCards(mergedCards);
  };
  useEffect(() => {
    trackMyAccount(GA_WALLET, 'Wallet Page', account);
    getCards();
  }, [selectedCardData, getCreditCards]);

  const handleMakeDefault = async (index) => {
    let updatedCardType = { ...allCards };
    setShowLoader(true);

    updatedCardType = allCards.map(async (card, i) => {
      if (i === index) {
        const updatedCard = { ...card, preferredcard: 'Y' };
        const newPayload = {
          token: updatedCard.token,
          cardType: updatedCard.cardtype,
          preferredCard: updatedCard.preferredcard,
        };
        try {
          const updatedCardData = await updateCreditCards(newPayload);
          if (updatedCardData?.errorCode) {
            setShowLoader(false);
            setAPIErrorMessage(updatedCardData.body);
          } else {
            setShowLoader(false);
          }
        } catch (error) {
          console.error('Error updating credit cards:', error);
        }
        return updatedCard;
      } else {
        return { ...card, preferredcard: 'N' };
      }
    });

    updatedCardType = await Promise.all(updatedCardType);

    setAllCards(updatedCardType);
  };

  const creditCardModal = async (cardData, index) => {
    setEditCardDetails(false);
    setSelectedCardData(cardData);
    setIsOpen(true);
  };
  const deleteOverLay = (token) => {
    setSelectedCardForDelete(token);
    setShowRemove(true);
  };
  async function handleDeleteButtonClick(cards) {
    setSelectedCardForDelete(null);
    setShowLoader(true);
    try {
      const updatedCardData = await deleteCreditCards(cards);
      mutate('/action/account/getAccount');
      setSelectedCardData(updatedCardData);
      setShowLoader(false);
      if (updatedCardData?.errorCode) {
        setAPIErrorMessage(updatedCardData.body);
      }
    } catch (error) {
      setShowLoader(false);
      console.error('Error updating credit cards:', error);
    }
  }

  const [loginModal, setLoginModal] = useState(false);
  const [isRememberMeSessionExpired, setRememberMeSessionExpired] = useState(false);
  function closeLoginModal() {
    setLoginModal(false);
  }

  const addAndUpdateWallet = (chosedOption, cards, index) => {
    const walletActions = {
      addCard: () => {
        setEditCardDetails(true);
        setIsOpen(true);
      },
      editCard: () => {
        creditCardModal(cards, index);
        setSelectedCardForDelete(null);
      },
      deleteCard: () => {
        deleteOverLay(cards?.token);
      },
    };
    const actionToBePerformed = walletActions[chosedOption];
    const expirationDetails = checkSessionExpiration(account?.lastActivity, studioConfig?.sessionAuthExpirationMins);

    if (expirationDetails?.checkedKeepMeSignedIn) {
      setRememberMeSessionExpired(expirationDetails?.isUserSessionExpired);
      if (expirationDetails?.isUserSessionExpired) {
        setLoginModal(true);
      } else {
        if (actionToBePerformed) {
          actionToBePerformed();
        }
      }
    } else {
      if (actionToBePerformed) {
        actionToBePerformed();
      }
    }
  };

  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  const [cardDisplayNames, setCardDisplayNames] = useState({});
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
    if (studioConfig?.cardTypeDisplayName) {
      try {
        setCardDisplayNames(JSON.parse(studioConfig?.cardTypeDisplayName));
      } catch (error) {
        console.error('Errorparsing projectConfig for cardTypeDisplayName:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'credit_cards',
            cart: {
              ...data,
            },
          },
          monetateConfig,
        );
      };
      if (account && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [account, monetateConfig]);

  return (
    <>
      <div aria-labelledby="wallet-section" className="flex justify-between">
        <h3 id="wallet-section" className="text-[21px] font-bold leading-6 text-gray-900">
          {formatCommonMessage({ id: 'wallet', defaultMessage: 'Wallet' })}
        </h3>
        <button
          type="button"
          className="ease-out; outline-width: 0px; text-center text-base font-medium tracking-wide text-[#043C63] transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-300 lg:block"
          onClick={() => {
            addAndUpdateWallet('addCard', null, null);
          }}
        >
          {formatAccountMessage({ id: 'addCreditCard', defaultMessage: 'Add New Credit Card' })}
        </button>
      </div>
      {!showGiftCards && <Loader />}
      {apiErrorMessage && <p className="text-left text-red-900">{apiErrorMessage}</p>}

      <div className="mt-2 mb-8 grid grid-cols-1 gap-4 text-gray-900 md:grid-cols-2">
        {allCards?.map((cards, index) => {
          return (
            <div
              className={`rounded border ${
                cards?.preferredcard === 'Y' ? 'order-1 border-blue-500' : 'order-2 border-slate-300'
              } p-4`}
              key={index}
            >
              <div className="flex-1 text-sm">
                <p className="mb-2">
                  My {cards?.cardtype === 'Mastercard' ? 'Master' : cardDisplayNames?.[cards?.cardtype]}
                </p>

                <dt className="mb-[5px] flex items-center justify-start">
                  <p className="h-6  w-[35.48px]">{RenderCardsImages(cards?.cardtype)}</p>
                  <span className="ml-[7.52px] font-medium ">
                    {cardDisplayNames?.[cards?.cardtype]} ending {cards?.token?.slice(-4)}
                  </span>
                  {(cards?.isDefault || cards?.preferredcard === 'y' || cards?.preferredcard === 'Y') && (
                    <section className="flex items-center">
                      <StarIcon className="mr-1 ml-[15px] h-[15px] w-[15.79px] text-[#043C63]" />{' '}
                      <p className="text-[#333333]">
                        {formatCartMessage({ id: 'defaultPayment', defaultMessage: 'Default Payment' })}
                      </p>
                    </section>
                  )}
                </dt>

                <p>
                  <section className="mb-2 flex items-center">
                    {cards?.isExpired ? (
                      <section className="flex items-center">
                        {' '}
                        <WarningIcon className="mr-2" />
                        <p className="text-red-900">
                          {formatAccountMessage({ id: 'Expired', defaultMessage: 'Expired' })}
                        </p>{' '}
                      </section>
                    ) : (
                      <p className="text-[#333]">Expires</p>
                    )}
                  </section>
                  {CalculateExpDate(cards?.expirydate)}
                </p>

                <span className="mt-[15px]  flex shrink-0 items-start space-x-2">
                  <button
                    type="button"
                    className="rounded-md text-sm font-medium text-[#043C63] transition hover:text-accent-600 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
                    onClick={() => {
                      addAndUpdateWallet('editCard', cards, index);
                    }}
                  >
                    {formatCommonMessage({ id: 'edit', defaultMessage: 'Edit' })}
                  </button>
                  <span className="text-gray-300" aria-hidden="true">
                    |
                  </span>
                  <section className="relative">
                    <button
                      type="button"
                      className="rounded-md text-sm font-medium text-[#043C63] transition hover:text-accent-600 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
                      onClick={() => {
                        addAndUpdateWallet('deleteCard', cards, null);
                      }}
                    >
                      {formatCommonMessage({ id: 'delete', defaultMessage: 'delete' })}
                    </button>

                    {selectedCardForDelete === cards?.token && (
                      <section className="absolute left-[-135px] top-[-100px] w-72 rounded-sm border border-gray-600 bg-white p-3 text-center">
                        <section className="boxArrow absolute left-36 -bottom-4 h-0 w-0 rotate-180 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600">
                          <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
                        </section>
                        <p className="mb-2 font-bold text-gray-900">
                          {formatCommonMessage({
                            id: 'areYouSureDelete',
                            defaultMessage: 'Are you sure you want to delete?',
                          })}{' '}
                        </p>

                        <button
                          type="button"
                          onClick={() => handleDeleteButtonClick(cards)}
                          className="mr-2 rounded-sm border border-blue-900 bg-white py-1 px-4 font-bold text-blue-900 hover:bg-blue-900 hover:text-white"
                        >
                          {formatCommonMessage({ id: 'yes', defaultMessage: 'Yes' })}
                        </button>

                        <button
                          type="button"
                          className="rounded-sm border border-blue-900 bg-blue-900 py-1 px-4 font-bold text-white hover:bg-white hover:text-blue-900"
                          onClick={() => setSelectedCardForDelete(null)}
                        >
                          {formatCommonMessage({ id: 'no', defaultMessage: 'No' })}
                        </button>
                      </section>
                    )}
                  </section>

                  {!cards?.isDefault && cards?.preferredcard !== 'Y' && (
                    <section>
                      <span className="text-gray-300 " aria-hidden="true">
                        |
                      </span>

                      <button
                        type="button"
                        className="ml-2 rounded-md text-sm font-medium text-[#043C63] transition  focus:outline-none focus:ring-2  focus:ring-offset-2"
                        onClick={() => {
                          handleMakeDefault(index);
                        }}
                      >
                        Make Default
                      </button>
                    </section>
                  )}
                </span>
              </div>
            </div>
          );
        })}
      </div>

      {showGiftCards && (
        <>
          <div aria-labelledby="wallet-section" className="flex justify-between">
            <h3 id="wallet-section" className="text-[21px] font-bold leading-6 text-gray-900">
              {formatCartMessage({ id: 'giftCard', defaultMessage: 'Gift Card' })}
            </h3>
          </div>
          <p className="mt-2 text-base text-[#043C63]">
            <button onClick={() => setIsBalanceModalOpen(true)}>
              {formatCartMessage({ id: 'checkGiftCardBal', defaultMessage: 'Check Gift Card Balance' })}
            </button>
          </p>
        </>
      )}
      {showLoader && <Loader />}

      {isBalanceModalOpen && (
        <CheckGiftCardModal
          closeBalanceModal={() => setIsBalanceModalOpen(false)}
          configuredData={studioConfig}
        ></CheckGiftCardModal>
      )}
      {isOpen && (
        <AddNewCard
          showCardField={EditCardDetails}
          data={selectedCardData}
          setSelectedCardData={setSelectedCardData}
          closeModal={() => setIsOpen(false)}
        />
      )}
      {loginModal && (
        <LoginModal
          loginModal={loginModal}
          closeModal={closeLoginModal}
          closeAccountFlyout={closeLoginModal}
          loginCallBack={async () => {
            setShowLoader(true);
            await getCards();
            setShowLoader(false);
          }}
          isRememberMeSessionExpired={isRememberMeSessionExpired}
        />
      )}
    </>
  );
};

export default Wallet;
