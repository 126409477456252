import { Variant } from '@Types/product/Variant';

export const getVariantPromoAttributes = (variant: Variant, promoFields: string) => {
  try {
    const parsedPromoFields = JSON.parse(promoFields);
    const promotionAttributes = variant?.attributes?.promotionAttributes || [];
    const brands = variant?.attributes?.brand || '';
    const departmentCode = variant?.attributes?.departmentCode || '';
    const isDiscountable = variant?.attributes?.isDiscountable ?? true;
    const result = {
      brands,
      departmentCode,
      isDiscountable,
    };
    if (promotionAttributes?.length > 0 && parsedPromoFields?.length > 0) {
      parsedPromoFields?.forEach((item) => {
        const { field, labels, isList = false } = item;
        // Iterate over each pattern in the promotionAttributes array
        if (isList) {
          const listItems = [];
          for (const pattern of promotionAttributes) {
            const [key, value] = pattern.split('=');
            // If any value from the parsedPromoFields matches the pattern key, push into list items
            if (labels?.includes(key)) {
              listItems.push(value);
            }
          }
          if (listItems?.length > 0) {
            result[field] = listItems;
          }
        } else {
          for (const pattern of promotionAttributes) {
            const [key, value] = pattern.split('=');
            // If any value from the parsedPromoFields matches the pattern key, set the result field
            if (labels?.includes(key)) {
              result[field] = value;
              break; // Stop after the first match
            }
          }
        }
      });
    }
    return {
      result,
      skuId: variant?.sku,
      activePrice: variant?.price?.centAmount,
    };
  } catch (error) {
    console.log('Error while extracting promo attribute :', error);
    return {};
  }
};
