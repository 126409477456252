import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FiCheck } from 'react-icons/fi';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop, mediumDesktop } from 'helpers/utils/screensizes';
import { useAccount, useProduct } from 'frontastic';
import { getWishlist, addToWishlist, removeLineItem } from 'frontastic/actions/wishlist';
const LoginModal = dynamic(() => import('../../Modals/Login/login'));
import CustomMessageModal from '../../Modals/maxLimitModal';
import CompareProducts from '../compareSection';
import PriceDetails from './priceDetails';
import ProductCell from './productCell';

export default function ProductTile({
  products,
  totalCount,
  selectedPageView,
  handleCompareButtonClick,
  compareProducts,
  setCompareProducts,
}) {
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const [wishListItems, setWishlistItems] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { loggedIn } = useAccount();
  const { getDroplets } = useProduct();
  const { data: wishlist } = getWishlist(loggedIn);
  const [isdesktop] = useMediaQuery(desktop - 1);
  const [ismediumDesktop] = useMediaQuery(mediumDesktop);
  const screenSize = ismediumDesktop ? 4 : isdesktop ? 3 : 2;
  const [plpDroplets, setPlpDroplets] = useState([]);
  const [customMsgModal, setCustomMsgModal] = useState(null);
  let productCount = screenSize;

  const CompareSection = (variant) => {
    const extractedData = {
      skuId: variant?.skuId,
      mediumImage: variant?.mediumImage || '',
      displayName: variant?.displayName || '',
    };
    const handleButtonClick = () => {
      handleCompareButtonClick(extractedData);
    };
    return (
      <button
        onClick={() => handleButtonClick()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleButtonClick();
          }
        }}
        tabIndex={0}
        className="flex w-fit gap-x-2 md:mt-3"
      >
        <div className="flex h-[18px] w-[18px] items-center justify-center rounded border border-[#0000004D]">
          {compareProducts?.some((item) => item?.skuId === variant?.skuId) ? (
            <FiCheck className="h-full w-full text-blue-900" />
          ) : null}
        </div>
        <span className="text-sm leading-5 text-blue-900">
          {formatMessage({ id: 'compareProduct', defaultMessage: 'Compare' })}
        </span>
      </button>
    );
  };

  const LoginForPriceSection = () => {
    return (
      <span
        onClick={() => {
          setLoginModal(true);
        }}
        className=" w-fit cursor-pointer text-sm leading-5 text-blue-900 hover:underline"
      >
        {formatMessage({ id: 'logForPricing', defaultMessage: 'Log in for Exchange pricing' })}
      </span>
    );
  };

  const PriceDetailSection = (product) => {
    return <PriceDetails product={product} />;
  };

  const handlePDPNavigation = (pushURL) => {
    setIsLoad(true);
    router?.push(pushURL).then(() => {
      setIsLoad(false);
    });
  };

  const handleAddWishlist = (sku) => {
    if (loggedIn) {
      setIsLoad(true);
      addToWishlist(sku, 1)
        .then((response) => {
          if (response?.errorCode) {
            setCustomMsgModal(response?.body);
          }
        })
        .finally(() => {
          setIsLoad(false);
        });
    } else {
      setLoginModal(true);
    }
  };

  const handleRemoveWishlist = (sku) => {
    setIsLoad(true);
    removeLineItem(sku)
      .then((res) => {
        setIsLoad(false);
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  function closeLoginModal() {
    setLoginModal(false);
  }

  const handleBorder = (index) => {
    if (index + 1 === selectedPageView) return null;
    if (index + 1 === productCount) {
      productCount = productCount + screenSize;
      return 'border-b-1';
    }
    if (index + 1 > selectedPageView - screenSize) return 'border-r-1';

    return 'border-b-1 border-r-1';
  };

  const fetchPLPDroplets = async () => {
    function extractAttributes(product) {
      const categories = [
        ...new Set(
          product?.parentCategories?.flatMap((category) => category?.split(' > ')?.map((item) => item?.trim())),
        ),
      ];
      const brands = product?.brands || '';
      const departmentCode = product?.departmentCode || '';
      const isDiscountable = product?.isDiscountable || true;

      return {
        categories,
        brands,
        departmentCode,
        isDiscountable,
        ...(product?.productSubType && { productSubType: product.productSubType }),
        ...(product?.style && { style: product.style }),
        ...(product?.gender && { gender: product.gender }),
        ...(product?.collections && { collections: product.collections }),
        ...(product?.productfeatures && { productfeatures: product.productfeatures }),
        ...(product?.occasions && { occasions: product.occasions }),
        ...(product?.league && { league: product.league }),
      };
    }
    function formatProductForApi(product) {
      const variant = product?.variants[0];
      return {
        productId: product?.repositoryId,
        skuId: variant?.skuId,
        activePrice: variant?.activePrice,
        attributes: extractAttributes(product),
        ...(product?.category && { category: product.category }),
      };
    }
    const chunkSize = 32; // limit of the payload length
    const apiRequestPayload = products?.map(formatProductForApi);
    const promises = [];
    setIsLoad(true);
    for (let i = 0; i < apiRequestPayload?.length; i += chunkSize) {
      const chunk = apiRequestPayload.slice(i, i + chunkSize);
      promises.push(getDroplets(chunk));
    }

    try {
      const allPLPDroplets = await Promise.all(promises)
        .then((results) => results?.flat()?.filter((droplet) => !droplet?.errorCode))
        .catch((error) => {
          console.error('Error in PLP Droplets API calls:', error);
          return [];
        });
      setPlpDroplets(allPLPDroplets);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    const wishlistItems = wishlist?.lineItems;
    const filteredWishlistItems = [];
    if (loggedIn && wishlistItems) {
      wishlistItems?.forEach((item) => {
        filteredWishlistItems?.push({
          sku: item?.variant?.sku,
          lineItemId: item?.lineItemId,
        });
      });
      setWishlistItems(filteredWishlistItems);
    } else setWishlistItems(null);
  }, [wishlist, loggedIn]);

  useEffect(() => {
    fetchPLPDroplets();
    const initBazaarvoice = () => {
      if (window.$BV) {
        clearInterval(intervalId);
        const productIds = products?.map((product) => product?.repositoryId);
        window.$BV.ui('rr', 'inline_ratings', {
          productIds: productIds,
          containerPrefix: 'BVRRInlineRating',
        });
      }
    };
    const intervalId = setInterval(() => {
      initBazaarvoice();
    }, 500);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [products]);

  return (
    <>
      <section className="my-3 grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-0 lg:grid-cols-3 xl:grid-cols-4">
        {products?.slice(0, products?.length).map((product, index) => {
          const productDroplets =
            plpDroplets?.find((droplet) => droplet?.skuId === product?.variants?.[0]?.skuId) || {};
          return (
            <ProductCell
              key={index}
              product={product}
              index={index}
              CompareSection={CompareSection}
              handleAddWishlist={handleAddWishlist}
              handleRemoveWishlist={handleRemoveWishlist}
              handleBorder={handleBorder}
              wishListItems={wishListItems}
              PriceDetailSection={PriceDetailSection}
              LoginForPriceSection={LoginForPriceSection}
              handlePDPNavigation={handlePDPNavigation}
              productDroplets={productDroplets}
            />
          );
        })}
      </section>
      <section>
        <div className="min-h-24 fixed bottom-0 left-0 z-10 mb-0 w-full bg-[#F1F1F1F2] shadow-[0px_-3px_4px_0px_#00000040]">
          <CompareProducts compareProducts={compareProducts} setCompareProducts={setCompareProducts} />
        </div>
      </section>
      {isLoad && <Loader />}
      <LoginModal
        loginModal={loginModal}
        closeModal={closeLoginModal}
        closeAccountFlyout={closeLoginModal}
        loginCallBack={() => router.push(router.asPath)}
      />
      {customMsgModal !== null && (
        <CustomMessageModal
          closeModal={() => {
            setCustomMsgModal(null);
          }}
          customMessage={customMsgModal}
        />
      )}
    </>
  );
}
