import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import jsPDF from 'jspdf';
import FileBase64 from 'react-file-base64';
import { useForm } from 'react-hook-form';
import useSWR, { mutate } from 'swr';
import MiniLoader from 'components/commercetools-ui/loader/miniLoader';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart, useAccount, fetchApiHub, revalidateOptions } from 'frontastic';
import FindStore from '../../../../../commercetools-ui/aafes/Modals/findStore';
import ReturnStore from '../../../../../icons/returnStore';
import Printer from '../../../../../images/printer.png';
import ReturnCarrier from '../../../../../images/returnCarrier.png';

function ReturnItemForm({
  returnOrder,
  closeModal,
  setReturnId,
  setIsVisible,
  setToFetchOrderDetails,
  orderInfo,
  setItemReturnModal,
}) {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { returnItem, listingStores, loggedIn, itemReturnLabel, getOrderDetail } = useAccount();
  const [reason, setReason] = useState({ chooseReason: '' });
  const [isEmpty, setIsEmpty] = useState(false);
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const editItemQuantity = (lineItemId: string, newQuantity: number) => updateItem(lineItemId, newQuantity);
  const { updateItem, returnClaimForm, returnEmailInstructions, studioConfig: projectConfiguration } = useCart();
  const [findStoreModal, setFindStoreModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [channelId, setChannelId] = useState(null);
  const [storeList, setStoresList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showClaimForm, setShowClaimForm] = useState(false);
  const [comments, setComments] = useState('');
  const [commentsError, setCommentsError] = useState('');
  const [imgError, setImgError] = useState('');
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(true);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [returnLabelId, setReturnLabelId] = useState('');
  const [isReturnInitiated, setIsReturnInitiated] = useState(false);
  const [maoOrderDetails, setMaoOrderDetails] = useState({});
  const [retItem, setRetItem] = useState();
  const [returnLabel, setReturnLabel] = useState(null);
  const [error, setError] = useState('');
  const [mailSentConfirmation, setMailSentConfirmation] = useState(false);
  const [isReturnClaimed, setIsReturnClaimed] = useState(false);
  const [isReturnClaimInitiated, setIsReturnClaimInitiated] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [getAllCustomMessages, setGetCustomMessages] = useState(null);

  let selectReason: any = [];
  if (projectConfiguration?.returnReasons) {
    selectReason = JSON.parse(projectConfiguration?.returnReasons);
  }

  const [selectedReason, setSelectedReason] = useState(null);

  const fileInputRef = useRef(null);

  function handleNameChange(e) {
    setCommentsError('');
    setImgError('');
    setError('');
    const selectedValue = e.target.value;
    setSelectedReason(selectedValue);
    const selectedOptionObject = selectReason.find((option) => option.value === selectedValue);
    if (selectedOptionObject && selectedOptionObject.triggerClaimFrom) {
      setShowClaimForm(true);
    } else {
      setShowClaimForm(false);
    }
  }

  const [chosenFileNames, setChosenFileNames] = useState([]);
  const [attachedContents, setAttachedContents] = useState([]);
  function arrayBufferToString(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    return String.fromCharCode.apply(null, uint8Array);
  }
  const handleUploadImageContent = (files) => {
    const UploadedImageContent = [...attachedContents];
    const uploaded = [...chosenFileNames];
    files?.forEach((file) => {
      UploadedImageContent.push(file);
      uploaded.push(file?.fileName);
    });
    setAttachedContents(UploadedImageContent);
    setChosenFileNames(uploaded);
  };

  const handleFileChange = (e) => {
    if (selectedReason !== 'ItemLost') {
      setImgError('');
      const chosenImages = e.target.files;

      const getContent = (img) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(img);
          reader.onload = function () {
            const content =
              typeof reader.result === 'string'
                ? reader.result.split('base64,')[1]
                : arrayBufferToString(reader.result).split('base64,')[1];
            resolve(content);
          };
          reader.onerror = function (error) {
            reject(error);
          };
        });
      };

      const getImageDetails = async () => {
        const updatedImages = await Promise.all(
          Object.keys(chosenImages).map(async (image) => {
            const content = await getContent(chosenImages[image]);
            return {
              fileName: chosenImages[image].name,
              attachmentContent: content,
            };
          }),
        );
        handleUploadImageContent([...updatedImages.map((img) => img)]);
      };
      getImageDetails();
    }
  };
  const mutateMAOAPI = async (orderId) => {
    const itemReturnData = await getOrderDetail(orderId);
    setMaoOrderDetails(itemReturnData);
  };
  useEffect(() => {
    mutateMAOAPI(returnOrder.orderId);
  }, []);

  const { data } = useSWR(
    `/action/customObjects/getCustomMessages?messages=ReturnProcessFailed`,
    fetchApiHub,
    revalidateOptions,
  );

  useEffect(() => {
    setGetCustomMessages(data);
  }, [data]);

  async function processReturnClaim(
    data,
    selectedQuantity,
    selectedReason,
    comments,
    attachedContents,
    setReturnLabelId,
    setReturnId,
    setIsButtonEnabled,
    setError,
    setIsReturnInitiated,
    setIsReturnClaimInitiated,
    setIsReturnClaimed,
    returnClaimForm,
    response,
    itemReturnData,
    lastItemReturnLabelDocId?: string,
  ) {
    if (showClaimForm) {
      const claimPayload = {
        orderId: data.orderId,
        orderLineId: data.orderLineId,
        quantity: selectedQuantity,
        itemId: data.itemId,
        returnReason: selectedReason,
        returnNotes: comments,
        attachmentContent: attachedContents,
      };

      setIsReturnInitiated(false);
      setIsReturnClaimInitiated(true);

      const res = await returnClaimForm(claimPayload);
      const claimResponse = res as any;

      setIsReturnClaimInitiated(false);
      if (
        ('errorCode' in claimResponse && claimResponse?.errorCode === 500) ||
        !claimResponse?.returnClaimFormDetails?.caseId
      ) {
        setIsButtonEnabled(false);
        setError(claimResponse?.body ? claimResponse?.body : 'Return Claim Failed!');
        if (claimResponse?.reSet) {
          window.location.reload();
        }
      } else {
        setIsReturnClaimed(true);
      }
    }

    if (response?.orderReturn?.returnLabelDocId) {
      setReturnLabelId(itemReturnData?.orderReturn?.returnLabelDocId);
      setReturnId(itemReturnData?.orderReturn?.returnLabelDocId);
      setIsButtonEnabled(true);
    }
    if (lastItemReturnLabelDocId) {
      setReturnLabelId(lastItemReturnLabelDocId);
      setReturnId(lastItemReturnLabelDocId);
      setIsButtonEnabled(true);
    }
  }

  const itemReturn = async (data, orderInfo) => {
    setIsSubmitButtonEnabled(false);
    const payload = {
      orderId: data.orderId,
      orderLineId: data.orderLineId,
      itemId: data.itemId,
      quantity: selectedQuantity,
      returnReason: selectedReason,
      price: data.unitPrice,
      shippingMethodId: data.shippingMethodId,
    };
    if (data?.pickupDetails) {
      (payload['channelKey'] = data?.pickupDetails?.key),
        (payload['firstName'] = orderInfo[0]?.customerFirstName),
        (payload['lastName'] = orderInfo[0]?.customerLastName);
    }
    const itemReturnData = await returnItem(payload);
    const response = itemReturnData as any;
    if (response?.orderReturn?.isReturn) {
      mutate('/action/cart/getMaoOrder', data?.orderId);
      setItemReturnModal(true);
      setIsReturnInitiated(true);
      setRetItem(itemReturnData);
      setIsSubmitButtonEnabled(false);
      setError('');
      setToFetchOrderDetails(true);
      await processReturnClaim(
        data,
        selectedQuantity,
        selectedReason,
        comments,
        attachedContents,
        setReturnLabelId,
        setReturnId,
        setIsButtonEnabled,
        setError,
        setIsReturnInitiated,
        setIsReturnClaimInitiated,
        setIsReturnClaimed,
        returnClaimForm,
        response,
        itemReturnData,
      );
    } else if (response?.errorCode === 509) {
      const fetchOrderDetailsWithRetry = async (getOrderIdFetchCount = 0) => {
        try {
          const getOrderIdResponse = await getOrderDetail(data?.orderId);
          const itemDetail = getOrderIdResponse?.maoOrderDetails?.orderLine?.find(
            (item) => item?.orderLineId === data?.orderLineId,
          );

          if (itemDetail?.returnIds?.length !== returnOrder?.returnIds.length) {
            const lastItemReturnLabelDocId =
              itemDetail?.returnLabelIds[itemDetail?.returnLabelIds.length - 1]?.ReturnLabelDocId;
            setItemReturnModal(true);
            setIsReturnInitiated(true);
            setRetItem(itemReturnData);
            setIsSubmitButtonEnabled(false);
            setError('');

            await processReturnClaim(
              data,
              selectedQuantity,
              selectedReason,
              comments,
              attachedContents,
              setReturnLabelId,
              setReturnId,
              setIsButtonEnabled,
              setError,
              setIsReturnInitiated,
              setIsReturnClaimInitiated,
              setIsReturnClaimed,
              returnClaimForm,
              response,
              itemReturnData,
              lastItemReturnLabelDocId,
            );

            return;
          } else {
            setIsSubmitButtonEnabled(true);
          }

          if (getOrderIdFetchCount < projectConfiguration?.getOrderIdFetchAttemptCounter) {
            setTimeout(() => {
              fetchOrderDetailsWithRetry(getOrderIdFetchCount + 1);
            }, projectConfiguration?.getOrderIdExpirationSecs);
          } else {
            setIsReturnClaimInitiated(false);
            setError(getAllCustomMessages?.ReturnProcessFailed);
          }
        } catch (error) {
          console.error('Error fetching cartList:', error);
          setIsReturnClaimInitiated(false);
          setError(getAllCustomMessages?.ReturnProcessFailed);
        }
      };
      fetchOrderDetailsWithRetry();
    } else {
      setIsSubmitButtonEnabled(true);
      setError(itemReturnData?.body);
      if (response?.reSet) {
        window.location.reload();
      }
    }
  };

  const getEmailInstructions = async () => {
    const instructions = await returnEmailInstructions();
    if (instructions?.emailSent) {
      setMailSentConfirmation(true);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (showClaimForm) {
      if (comments !== '' && (selectedReason === 'ItemLost' || chosenFileNames.length !== 0)) {
        itemReturn(returnOrder, orderInfo);
      } else {
        if (comments === '') {
          setCommentsError('Return Comments is required *');
        }
        if (chosenFileNames.length === 0) {
          setImgError('Photo is required *');
        }
      }
    } else {
      itemReturn(returnOrder, orderInfo);
    }
  };

  const handleReturnLabel = async () => {
    const returnLabelData = await itemReturnLabel(returnLabelId);
    const returnLabel = await returnLabelData;
    setReturnLabel(returnLabel);
    if (returnLabel) {
      const base64String = returnLabel?.orderReturnLabel;
      const buffer = Buffer.from(base64String, 'base64');
      const blob = new Blob([buffer], { type: 'application/pdf' }); // Set the type to "application/pdf"
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank');
    }
  };

  function closeStoreModal() {
    setFindStoreModal(false);
  }
  function updateChannelId(id) {
    setChannelId(id);
  }

  const showStoreModal = () => {
    setFindStoreModal(true);
    setSearchInput('');
    const fetchChannelList = async () => {
      const channelList: any = await listingStores('');
      setStoresList(channelList);
    };
    loggedIn && fetchChannelList();
  };

  const qty = returnOrder?.returnableQuantity;
  const numbers = Array.from({ length: qty }, (_, index) => index + 1);

  const handleGeneratePdf = () => {
    const doc = new jsPDF('p', 'px', 'a4');
    const element = document.getElementById('orderDetails');
    setIsVisible(false);

    element.style.margin = '0 10mm';
    element.style.verticalAlign = 'middle';

    doc.html(element, {
      callback: (doc) => {
        doc.save('OrderDetails.pdf');
        setIsVisible(true);
      },
      filename: 'OrderDetails.pdf',
      html2canvas: { scale: 0.42 },
    });
  };

  return (
    <>
      <section>
        {error && (
          <p className="text-affes-md mb-5 rounded-sm border border-red-300 bg-red-100 p-2 text-center font-bold text-red-900">
            {error}
          </p>
        )}
        {isReturnClaimInitiated && (
          <div className="text-affes-md mx-20 mb-5 flex justify-center rounded-sm border border-gray-300 bg-gray-100 p-2 font-bold text-gray-900 ">
            <MiniLoader value="Return Claim Status" />
          </div>
        )}
        {isReturnInitiated && (
          <p className="text-affes-md mx-20 mb-5 rounded-sm border border-green-300 bg-green-100 p-2 text-center font-bold text-green-900 ">
            {formatAccountMessage({ id: 'successfulReturn', defaultMessage: 'Return initiated Successfully !' })}
          </p>
        )}
        {isReturnClaimed && (
          <p className="text-affes-md mx-20 mb-5 rounded-sm border border-green-300 bg-green-100 p-2 text-center font-bold text-green-900 ">
            {formatAccountMessage({ id: 'successfulReturnClaim', defaultMessage: 'Return Claimed Successfully !' })}
          </p>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="flex justify-between pb-5">
            <section className="flex gap-[14px] md:gap-[15px]">
              <section className="shrink-0">
                <Link href={`slug/p/${returnOrder?.itemId}`}>
                  <img
                    alt="Return Item Image"
                    height={90}
                    width={90}
                    className="h-[75px] w-[75px] cursor-pointer lg:h-[90px] lg:w-[90px]"
                    src={returnOrder?.smallImageURI}
                  />
                </Link>
              </section>
              <section>
                <section>
                  <p className="max-w-[324px]">
                    <Link href={`slug/p/${returnOrder?.itemId}`}>
                      <p className="mb-[6px] cursor-pointer text-affes-sm font-bold leading-5 text-blue-900">
                        {' '}
                        {returnOrder?.itemShortDescription}
                      </p>
                    </Link>
                  </p>
                  <p className="mb-[6px] text-affes-base font-medium leading-5 text-gray-800">
                    {formatAccountMessage({
                      id: 'item.id',
                      defaultMessage: 'Item #: ',
                    })}
                    {returnOrder?.itemId}
                  </p>
                  <section>
                    <p className="text-affes-sm font-bold leading-[16.8px] text-red-900">
                      {`$`}
                      {returnOrder?.unitPrice?.centAmount}
                    </p>
                  </section>
                </section>
              </section>
            </section>
            <section>
              <section>
                <p className="mb-2 text-affes-sm font-semibold leading-[20px] text-gray-800">
                  {' '}
                  {formatAccountMessage({ id: 'Qty.count', defaultMessage: 'Qty' })}
                </p>
                <select
                  {...register('selectedQty', {
                    onChange: (e) => {
                      setSelectedQuantity(e.target.value);
                    },
                  })}
                  className="select rounded-sm border border-gray-600 text-affes-sm leading-5 text-gray-900"
                  value={selectedQuantity}
                >
                  {numbers.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </section>
            </section>
          </section>
          <hr className="mb-5 border-[rgba(0,0,0,0.2)]" />

          <section className="mb-8">
            <label className="mb-4 block text-affes-sm font-bold leading-[16.8px] text-gray-900">
              {formatAccountMessage({ id: 'return.Reason', defaultMessage: 'Why are you returning this item?' })}{' '}
              <sup className="text-[12px] font-bold leading-[14.4px] text-red-900">
                {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
              </sup>
            </label>
            <select
              defaultValue={selectedReason}
              {...register('selectedReason', {
                onChange: (e) => {
                  handleNameChange(e);
                },
                required: 'Please select a reason',
              })}
              className="select w-[25rem] rounded-sm border border-gray-600 text-affes-sm leading-5 text-gray-900"
            >
              <option value="">Please select a reason</option>
              {selectReason.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors.selectedReason && <p className="text-affes-sm text-red-500">{errors.selectedReason.message}</p>}
          </section>
          <section className="mb-5">
            <section className="my-1 flex flex-row items-center">
              <label className="mb-4 block text-affes-sm font-bold leading-[16.8px] text-gray-900">
                {formatAccountMessage({ id: 'comment', defaultMessage: 'Comments ' })}&nbsp;
              </label>
              {showClaimForm && (
                <sup className="text-xs font-bold text-[#Da0F0F]">
                  {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                </sup>
              )}
            </section>

            <textarea
              {...register('comments', {
                onBlur: (e) => {
                  setComments(e.target.value);
                  if (showClaimForm) {
                    if (e.target.value === '') {
                      setCommentsError('Return Comments is required *');
                    }
                  }
                },
                onChange: (e) => {
                  setCommentsError('');
                },
              })}
              className="w-full rounded-sm border border-gray-600 text-affes-sm leading-5 text-gray-900"
              rows={3}
            ></textarea>
            <p className="text-xs font-bold text-[#Da0F0F]">{commentsError}</p>
          </section>
          {showClaimForm && selectedReason !== 'ItemLost' && (
            <>
              <section className="my-1 flex flex-row items-center">
                <p className="text-sm font-bold text-[#333333]">
                  {formatAccountMessage({ id: 'photos.add', defaultMessage: 'Add Photos' })}
                </p>
                <sup className="text-xs font-bold text-[#Da0F0F]">*</sup>
              </section>
              <section className="flex flex-col">
                <section className={chosenFileNames?.length > 0 ? 'flex flex-col' : 'flex flex-row'}>
                  <section className="mr-2 mt-1">
                    <label htmlFor="filesUpload">
                      <a className="no-wrap rounded-sm bg-cyan-700  px-2 py-1 text-affes-xl font-bold leading-[19.2px] text-white">
                        {' '}
                        Choose Files{' '}
                      </a>
                    </label>
                    <input
                      id="filesUpload"
                      className="hidden"
                      type="file"
                      onChange={handleFileChange}
                      multiple
                      accept="image/*"
                    />
                  </section>
                  <section className="mt-1 flex flex-row flex-wrap">
                    {chosenFileNames?.length > 0 ? (
                      chosenFileNames.map((fname, index) => (
                        <p key={index} className="w-max pr-2 text-sm">
                          {fname}
                        </p>
                      ))
                    ) : (
                      <p>{formatAccountMessage({ id: 'noFilesChoosed', defaultMessage: 'No files chosen' })}</p>
                    )}
                  </section>
                </section>
                <p className="mt-1 text-xs font-bold text-[#Da0F0F]">{imgError}</p>
              </section>
            </>
          )}
          <section className="my-8">
            <button
              type="submit"
              className={`mr-10 h-[2.75rem] w-[13rem] cursor-pointer rounded-sm  text-affes-xl font-bold leading-[19.2px] text-white ${
                isSubmitButtonEnabled ? 'bg-blue-900' : 'cursor-not-allowed bg-gray-400'
              }`}
              disabled={!isSubmitButtonEnabled}
            >
              {formatAccountMessage({ id: 'submitRefundRequest', defaultMessage: 'Submit Refund Request' })}
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="h-[2.75rem] cursor-pointer text-affes-xl font-bold leading-[19.2px] text-blue-900"
            >
              Cancel
            </button>
          </section>

          <section className="mb-16">
            <h3 className="mb-4 text-[18px] font-bold text-[#333333]">
              {formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' })}
            </h3>
            <ul className="mb-3">
              <li className="mb-2 flex justify-between text-affes-sm leading-[16.8px] text-[#333333]">
                <p>{formatCartMessage({ id: 'aafesSubtotal', defaultMessage: 'Subtotal :' })}</p>
                <p>
                  <span className="inline-block w-[7rem] text-right font-bold">
                    $ {(returnOrder?.unitPrice?.centAmount * selectedQuantity).toFixed(2)}
                  </span>
                </p>
              </li>
              <li className="mb-2 flex justify-between text-affes-sm leading-[16.8px] text-[#333333]">
                <p>{formatCartMessage({ id: 'estimatedShipping', defaultMessage: 'Estimated Shipping:' })}</p>
                <p>
                  <span className="inline-block w-[7rem] text-right">
                    {formatCartMessage({ id: 'freeShipping', defaultMessage: 'Free' })}
                  </span>
                </p>
              </li>
              <li className="mb-2 flex justify-between text-affes-sm leading-[16.8px] text-[#333333]">
                <p>{formatCartMessage({ id: 'yourBenefit', defaultMessage: 'Your Benefit:' })}</p>
                {formatCartMessage({ id: 'noTax', defaultMessage: 'No Tax' })}
              </li>
              <li className="mb-6 flex justify-between text-affes-sm leading-[16.8px] text-[#333333]">
                <p>{formatAccountMessage({ id: 'discount', defaultMessage: 'Discount' })}:</p>
                <p>
                  <span className="inline-block w-[7rem] text-right">
                    ${' '}
                    {(
                      returnOrder?.unitPrice?.centAmount * selectedQuantity -
                      (returnOrder?.orderLineTotal / returnOrder?.quantity) * selectedQuantity
                    ).toFixed(2)}
                  </span>
                </p>
              </li>
              <li className="aafes-xl mb-2 flex justify-between font-bold text-[#333333]">
                <p>{formatCartMessage({ id: 'estimatedTotal', defaultMessage: 'Estimated Order Total:' })}</p>
                <p>
                  <span className="inline-block w-[7rem] text-right">
                    $ {((returnOrder?.orderLineTotal / returnOrder?.quantity) * selectedQuantity).toFixed(2)}
                  </span>
                </p>
              </li>
            </ul>
          </section>
        </form>
        {selectedReason !== 'ItemLost' && (
          <section className="mb- flex items-start">
            <p className="mr-3 w-7">
              <img src={Printer.src} alt="Printer" />
            </p>
            <section className="w-[28rem]">
              <h4 className="mb-3 text-affes-xl font-bold leading-[19.2px] text-gray-900">
                {formatAccountMessage({ id: 'returnShippingLabel', defaultMessage: 'Print Return Shipping Label' })}
              </h4>
              <p className="mb-3 text-affes-base leading-[19px] text-gray-900">
                {formatAccountMessage({
                  id: 'returnLabel.msg',
                  defaultMessage:
                    'Just print a label, attach it to the outside of your package, and drop it off at your nearest FedEx',
                })}
              </p>

              <p className="mb-3 text-affes-base leading-[19px] text-blue-900">
                <a
                  target="_blank"
                  href="https://www.shopmyexchange.com/customer-service/returns-exchanges/"
                  rel="noreferrer"
                >
                  {formatAccountMessage({ id: 'returnFee', defaultMessage: 'Return fee may be applicable' })}
                </a>
              </p>

              <section className="mb-5">
                <button
                  onClick={handleReturnLabel}
                  className={`mr-6 h-[2.75rem] w-[13rem] rounded border  bg-white text-affes-xl font-bold leading-[19.2px] ${
                    isButtonEnabled
                      ? 'cursor-pointer border-blue-900  text-blue-900'
                      : 'cursor-auto border-gray-500 text-gray-500'
                  } `}
                  disabled={!isButtonEnabled}
                >
                  {formatAccountMessage({ id: 'printreturn.label', defaultMessage: '"Print Return Label' })}
                </button>
              </section>
            </section>
          </section>
        )}
        {selectedReason !== 'ItemLost' && (
          <>
            <section className="mb-7 flex items-start">
              <p className="mr-3 w-7">
                <ReturnStore />
              </p>
              <section className="w-[28rem]">
                <h4 className="mb-3 text-affes-xl font-bold leading-[19.2px] text-gray-900">
                  {formatAccountMessage({ id: 'returnInStore', defaultMessage: 'Return In Store' })}
                </h4>
                <p className="mb-2 text-affes-base leading-[19px] text-gray-900">
                  {formatAccountMessage({
                    id: 'returnInStore.msg1',
                    defaultMessage: 'Print a receipt to take with you to the nearest exchange store.',
                  })}
                </p>
                <p className="mb-3 text-affes-base leading-[19px] text-gray-900">
                  {' '}
                  {formatAccountMessage({ id: 'returnInStore.msg2', defaultMessage: 'Returns in store are FREE!' })}
                </p>

                <button onClick={() => showStoreModal()} className="mb-3 text-affes-base leading-[19px] text-blue-900">
                  {formatAccountMessage({ id: 'findStore', defaultMessage: 'Find a Store' })}
                </button>

                <section className="mb-5">
                  <button
                    onClick={() => {
                      handleGeneratePdf();
                    }}
                    className="mr-6 h-[2.75rem] w-[13rem] cursor-pointer rounded border  border-blue-900  bg-white font-bold text-blue-900"
                  >
                    {formatAccountMessage({ id: 'printReceipt', defaultMessage: 'Print Receipt' })}
                  </button>
                </section>
              </section>
            </section>
            <section className="flex items-start">
              <p className="mr-3 w-7">
                <img src={ReturnCarrier.src} alt="ReturnCarrier" />
              </p>
              <section className="w-[28rem]">
                <h4 className="mb-3 text-affes-xl font-bold leading-[19.2px] text-gray-900">
                  {formatAccountMessage({
                    id: 'preferredCarrierService',
                    defaultMessage: 'Return at your Preferred Carrier Service',
                  })}
                </h4>
                <p className="mb-3 text-affes-base leading-[19px] text-gray-900">
                  {formatAccountMessage({
                    id: 'preferred.loc.msg',
                    defaultMessage: `Receive instructions for making your return directly at your preferred carrier location. They'll
                  handle the return - so you don't have to.`,
                  })}
                </p>
                <p className="mb-3 text-affes-base leading-[19px] text-gray-900">
                  {formatAccountMessage({
                    id: 'preferred.loc.msg2',
                    defaultMessage: 'Returns Shipping fees vary by carrier and may differ by location',
                  })}
                </p>

                <section className="mb-5">
                  <button
                    disabled={buttonDisable}
                    onClick={() => {
                      getEmailInstructions();
                      setButtonDisable(true);
                    }}
                    className="mr-6 h-[2.75rem] w-[13rem] cursor-pointer rounded border border-blue-900 bg-white text-affes-xl font-bold leading-[19.2px] text-blue-900"
                  >
                    {formatAccountMessage({ id: 'emailInstructions', defaultMessage: 'Email Me Instructions' })}
                  </button>
                  {mailSentConfirmation && (
                    <p className="text-affes-md my-3 font-bold text-green-900 ">
                      {formatCartMessage({
                        id: 'mailSentConfirmation',
                        defaultMessage: 'Instructions sent to your mail !',
                      })}
                    </p>
                  )}
                </section>
              </section>
            </section>
          </>
        )}
      </section>

      <FindStore
        findStoreModal={findStoreModal}
        closeModal={closeStoreModal}
        setLoginModal={setLoginModal}
        setFindStoreModal={setFindStoreModal}
        channelId={channelId}
        updateChannelId={updateChannelId}
        setStoresList={setStoresList}
        storeList={storeList}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
    </>
  );
}

export default ReturnItemForm;
