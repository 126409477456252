import React, { useMemo, useState } from 'react';
import { Variant } from '@Types/product/Variant';
import { UIProduct } from 'components/commercetools-ui/products/product-details';
import CheckmarkIcon from 'components/icons/checkmark';
import EditIcon from 'components/icons/editPencil';
import PickUpAtStoreIcon from 'components/icons/location-marker';
import ShipToAddressIcon from 'components/icons/shipping-truck';
import { useFormat } from 'helpers/hooks/useFormat';
import { isVeteranAccount } from 'helpers/utils/isVeteranAccount';
import { useAccount } from 'frontastic';
import ChangeStore from '../../Modals/changeStore';

export interface ShippingOptionsProps {
  product: UIProduct;
  variant: Variant;
  handleAddToCart: any;
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  shippingMethod: {
    isPickupInStore: any;
    isShipToAddress: any;
  };
  setShippingMethod: React.Dispatch<
    React.SetStateAction<{
      isPickupInStore: any;
      isShipToAddress: any;
    }>
  >;
  maxQuantity: number;
  showProductFrequency: boolean;
  isAddToCartDisabledForPUIS: boolean;
  isAddToCartDisabledForSTA: boolean;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
// TODO : will be managed dynamically in another ticket
function ShippingMethod() {
  return (
    <>
      <div className=" border-r-full rounded-tr-[3px] border-l-[35px] border-t-[35px] border-solid border-[#043C63] border-x-transparent border-b-transparent"></div>
      <div className="absolute top-1 right-1">
        <CheckmarkIcon />
      </div>
    </>
  );
}

function ShippingOptions({
  product,
  variant,
  handleAddToCart,
  quantity,
  setQuantity,
  shippingMethod,
  setShippingMethod,
  maxQuantity,
  showProductFrequency,
  isAddToCartDisabledForPUIS,
  isAddToCartDisabledForSTA,
}: ShippingOptionsProps) {
  const availableShippingMethods = {
    isPickupInStore: showProductFrequency ? false : variant?.isPickupInStore,
    isShipToAddress: variant?.isShipToAddress,
  };
  const isPickupInStore = variant?.isPickupInStore;
  const { account } = useAccount();
  const isPUISShippingMethodAvailabe = availableShippingMethods?.isPickupInStore && !isAddToCartDisabledForPUIS;
  const isSTAShippingMethodAvailabe = !isAddToCartDisabledForSTA && availableShippingMethods?.isShipToAddress;
  const isStoreOverlayButtonDisabled = !availableShippingMethods?.isPickupInStore || isVeteranAccount(account);
  const [isShowStoreModal, setIsShowStoreModal] = useState(false);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const shippingOptionData = useMemo(() => {
    return {
      _url: product?._url,
      variant: variant,
      name: product?.name,
    };
  }, [product, variant]);

  return (
    <>
      <section>
        <div className="flex justify-center gap-x-3.5 py-4 xl:mr-3">
          <label
            htmlFor="pickUpAtStore"
            role="radio"
            aria-checked={isPUISShippingMethodAvailabe && shippingMethod?.isPickupInStore}
            className={classNames(
              'h-[109px] w-full cursor-pointer rounded-[4px] border-[1px] border-solid bg-[#FFFFFF] md:h-[100px] md:max-w-[136px]',
              isPUISShippingMethodAvailabe && 'border-[#043C63]',
            )}
          >
            <div className="relative float-right h-[35px] w-[35px]">
              {isPUISShippingMethodAvailabe && shippingMethod?.isPickupInStore && <ShippingMethod />}
            </div>
            <div className="flex h-full items-center justify-center pl-[35px]">
              <div className="">
                <div className="flex justify-center py-2 text-[#333333] lg:pb-4">
                  <PickUpAtStoreIcon fill={`${isPUISShippingMethodAvailabe ? '#333333' : '#dddddd'}`} />
                  <input
                    disabled={!isPUISShippingMethodAvailabe}
                    type="radio"
                    id="pickUpAtStore"
                    name="shippingOption"
                    checked={isPUISShippingMethodAvailabe && shippingMethod?.isPickupInStore}
                    onChange={() =>
                      setShippingMethod((prevShippingMethod) => ({
                        ...prevShippingMethod,
                        isPickupInStore: true,
                        isShipToAddress: false,
                      }))
                    }
                    className="invisible h-0 w-0"
                    aria-labelledby="pickUpAtStoreLabel"
                  />
                </div>
                <div
                  id="pickUpAtStoreLabel"
                  className={`font-lato h-[17px] w-[95px] text-center text-[14px] font-normal leading-[17px] lg:h-[34px] lg:w-[63px] ${
                    isPUISShippingMethodAvailabe ? 'text-[#333333]' : 'text-[#dddddd]'
                  }`}
                >
                  {formatProductMessage({ id: 'pickUpAtStore', defaultMessage: 'Pick up at store' })}
                </div>
              </div>
            </div>
          </label>

          <label
            htmlFor="shipToAddress"
            role="radio"
            aria-checked={isSTAShippingMethodAvailabe && shippingMethod?.isShipToAddress}
            className={classNames(
              'h-[109px] w-full cursor-pointer rounded-[4px] border-[1px] border-solid bg-[#FFFFFF] md:h-[100px] md:max-w-[136px]',
              isSTAShippingMethodAvailabe && 'border-[#043C63]',
            )}
          >
            <div className="relative float-right h-[35px] w-[35px]">
              {isSTAShippingMethodAvailabe && shippingMethod?.isShipToAddress && <ShippingMethod />}
            </div>
            <div className="flex h-full items-center justify-center pl-[35px]">
              <div className="">
                <div className="flex justify-center py-2 text-[#043C63] lg:pb-4">
                  <ShipToAddressIcon fill={`${isSTAShippingMethodAvailabe ? '#043C63' : '#dddddd'}`} />
                  <input
                    disabled={!isSTAShippingMethodAvailabe}
                    type="radio"
                    id="shipToAddress"
                    name="shippingOption"
                    checked={shippingMethod?.isShipToAddress}
                    onChange={() =>
                      setShippingMethod((prevShippingMethod) => ({
                        ...prevShippingMethod,
                        isShipToAddress: true,
                        isPickupInStore: false,
                      }))
                    }
                    className="invisible h-0 w-0"
                    aria-labelledby="shipToAddressLabel"
                  />
                </div>
                <div
                  id="shipToAddressLabel"
                  className={`font-lato h-[17px] w-[96px] text-center text-[14px] font-normal leading-[17px] lg:h-[34px] lg:w-[63px] ${
                    isSTAShippingMethodAvailabe ? 'text-[#043C63]' : 'text-[#dddddd]'
                  }`}
                >
                  {formatProductMessage({ id: 'shipToAddress', defaultMessage: 'Ship to Address' })}
                </div>
              </div>
            </div>
          </label>
        </div>

        {isPickupInStore && account?.defaultStore?.key && (
          <div className="flex">
            <span>{account?.defaultStore?.name}</span>
            <button type="button" disabled={isStoreOverlayButtonDisabled} onClick={() => setIsShowStoreModal(true)}>
              <EditIcon
                className="ml-2 cursor-pointer"
                fill={`${!isStoreOverlayButtonDisabled ? '#043C63' : '#dddddd'}`}
              />
            </button>
          </div>
        )}
      </section>
      {isShowStoreModal && isPickupInStore && (
        <ChangeStore
          closeModal={() => setIsShowStoreModal(false)}
          data={shippingOptionData}
          isPDP={true}
          handleAddToCart={handleAddToCart}
          quantity={quantity}
          setQuantity={setQuantity}
          maxQuantity={maxQuantity}
        />
      )}
    </>
  );
}
export default ShippingOptions;
