import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import useSWR from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import { fetchApiHub, revalidateOptions, useAccount } from 'frontastic';

function Index({ cmsContent }) {
  const [cmsDescription, setCmsDescription] = useState(null);
  const [response, setResponse] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const addedScripts = useRef([]);
  const UUID = Math.random().toString(36).substring(2, 7);
  const { account } = useAccount();

  let getCMSTemp;
  const cmsPayload = { assetPath: cmsContent.assetID };
  let url;
  if (cmsContent.assetID) {
    const queryParams = new URLSearchParams({
      assetPath: cmsPayload.assetPath,
      login: 'false',
    });
    url = `/action/account/fetchLoginTemplate?${queryParams}`;
  }
  const { data, error } = useSWR(url, fetchApiHub, revalidateOptions);

  useEffect(() => {
    if (data && cmsPayload?.assetPath) {
      let isMounted = true;

      const executeScript = (scriptContent) => {
        // Check if a script with the same content already exists in the head
        const existingScript = addedScripts.current.find((script) => script.textContent === scriptContent);

        if (existingScript) {
          // If a script with the same content exists, remove it
          existingScript.remove();
          // Remove the existing script from the array
          addedScripts.current = addedScripts.current.filter((script) => script !== existingScript);
        }

        window.jsoncategory = undefined;

        if (typeof window.jsoncategory === 'undefined') {
          // Create script element and append it to the head
          const scriptElement = document.createElement('script');
          scriptElement.type = 'text/javascript';
          scriptElement.textContent = scriptContent;
          document.head.appendChild(scriptElement);
          // Add the new script element to the array
          addedScripts.current.push(scriptElement);
        }
      };
      function appendCMSDomData() {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = getCMSTemp.body;

        // Remove all <script> elements from the temporary element
        const scripts = Array.from(tempElement.querySelectorAll('script'));

        // Remove all <script> elements from the temporary element
        scripts.forEach((script) => script.remove());
        setResponse(tempElement.innerHTML);
        tempElement.remove();

        scripts.forEach((scriptTag) => {
          const scriptContent = scriptTag.innerHTML;
          const srcValue = scriptTag.getAttribute('src');
          if (scriptContent) {
            executeScript(scriptContent);
          }
          if (srcValue) {
            const scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.src = srcValue + '?t=' + Date.now();
            document.head.appendChild(scriptElement);
            // Add the script element to the ref
            addedScripts.current.push(scriptElement);
          }
        });
      }

      const getCMS = async () => {
        try {
          // const cmsPayload = { assetPath: cmsContent.assetID };
          // cmsPayload['loginCheck'] = false;

          if (!cmsContent?.customerGroups && !cmsContent?.customerCID) {
            getCMSTemp = await data;
            setShowLoader(true);
            if (!getCMSTemp?.body) {
              setCmsDescription(cmsContent?.descriptionField);
            } else {
              appendCMSDomData();
            }
          } else if (cmsContent?.customerGroups || cmsContent?.customerCID) {
            const cmsCustomerGroups = cmsContent?.customerGroups.split(',');
            const cmsCIDs = cmsContent?.customerCID?.split(',');
            const accountCID = String(account && account?.cid);
            const cmsCustomerGroupsMatched = cmsCustomerGroups.includes(account && account?.customerGroup);
            const cmsCIDMatched = cmsCIDs?.includes(accountCID);
            if (cmsCustomerGroupsMatched || cmsCIDMatched) {
              setShowLoader(true);
              getCMSTemp = await data;
              setShowLoader(false);
              if (!getCMSTemp?.body) {
                setCmsDescription(cmsContent?.descriptionField);
              } else {
                appendCMSDomData();
              }
            } else if (!account) {
              getCMSTemp = '';
              setCmsDescription(cmsContent?.descriptionField);
            }
          }
        } catch (error) {
          console.error('Error fetching data from API:', error);
        } finally {
          setShowLoader(false);
        }
      };
      if (isMounted && cmsContent.assetID) {
        getCMS();
      }

      return () => {
        isMounted = false;
        // Remove all dynamically added script elements from the head
        addedScripts.current.forEach((script) => {
          script.remove();
        });
        // Clear addedScripts ref
        window.jsoncategory = undefined;
        addedScripts.current = [];
      };
    }
  }, [data]);

  useEffect(() => {
    // Reset response state if cmsContent.assetID is null
    if (!cmsContent?.assetID) {
      setResponse(null);
    }
  }, [data]);

  return (
    <section aria-labelledby="homePageCms-Content-section" className={`cms-container-${UUID} mb-5`}>
      <h1 id="homePageCms-Content-section" className="sr-only"></h1>
      <div className="">
        {!cmsDescription ? (
          <div dangerouslySetInnerHTML={{ __html: response }} />
        ) : (
          <>
            <section>{cmsDescription}</section>
            <Link href={cmsContent?.redirectTo}>
              <button className="block w-20 rounded-sm bg-blue-900 py-2 text-affes-xl font-bold text-white">
                {cmsContent?.label}
              </button>
            </Link>
          </>
        )}
        {showLoader && <Loader />}
      </div>
    </section>
  );
}

export default Index;
